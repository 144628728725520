import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterModule } from '@angular/router';

import { OfflineComponent } from './components/offline/offline.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { QuantityOperationComponent } from './components/quantity-operation/quantity-operation.component';
import { ToastComponent } from './components/toast/toast.component';
import { FooterComponent } from './components/footer/footer.component';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { CartItemsComponent } from './components/cart-items/cart-items.component';
import { ModalComponent } from './components/modal/modal.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OnlinePaymentComponent } from './components/online-payment/online-payment.component';
import { PaymentTypesComponent } from './components/payment-types/payment-types.component';
import { CartBillsComponent } from './components/cart-bills/cart-bills.component';
import { TableNumberComponent } from './components/table-number/table-number.component';
import { BranchesListComponent } from './components/branches-list/branches-list.component';
import { AddressListComponent } from './components/address-list/address-list.component';
import { BranchAvailabilityPipe } from './pipes/branchAvailability.pipe';
import { ArabicNumberFormatPipe } from './pipes/ar-numbers.pipe';
import { OrderReceiptComponent } from './components/order-receipt/order-receipt.component';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { OrderTypeComponent } from './components/main-order-mode/order-type/order-type.component';
import { OrderTimeComponent } from './components/main-order-mode/order-time/order-time.component';
import { MainOrderModeComponent } from './components/main-order-mode/main.component';
import { NgxSplideModule } from 'ngx-splide';

@NgModule({
  declarations: [
    OfflineComponent,
    PhoneInputComponent,
    ArabicNumberFormatPipe,
    QuantityOperationComponent,
    ToastComponent,
    FooterComponent,
    CartItemComponent,
    CartItemsComponent,
    ModalComponent,
    OrderDetailsComponent,
    OnlinePaymentComponent,
    PaymentTypesComponent,
    CartBillsComponent,
    TableNumberComponent,
    BranchesListComponent,
    AddressListComponent,
    BranchAvailabilityPipe,
    OrderReceiptComponent,
    PaymentFailedComponent,
    MainOrderModeComponent,
    OrderTypeComponent,
    OrderTimeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NgxSkeletonLoaderModule,
    RouterModule,
    NgxSplideModule,
  ],
  exports: [
    TranslateModule,
    NgxSkeletonLoaderModule,
    OfflineComponent,
    PhoneInputComponent,
    ArabicNumberFormatPipe,
    QuantityOperationComponent,
    ToastComponent,
    FooterComponent,
    CartItemsComponent,
    ModalComponent,
    OrderDetailsComponent,
    OnlinePaymentComponent,
    PaymentTypesComponent,
    CartBillsComponent,
    TableNumberComponent,
    BranchesListComponent,
    AddressListComponent,
    BranchAvailabilityPipe,
    OrderReceiptComponent,
    PaymentFailedComponent,
    MainOrderModeComponent,
    OrderTypeComponent,
    OrderTimeComponent,
  ],
  providers: [ArabicNumberFormatPipe],
})
export class SharedModule {}
