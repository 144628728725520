import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import QrScanner from 'qr-scanner';
import { Restaurant } from 'src/app/core/models';
import { Branch } from 'src/app/core/models/branch.model';
import { BranchService } from 'src/app/core/services/branches.service';
import { OrderService } from 'src/app/core/services/order.service';
import { RestaurantService } from 'src/app/core/services/restaurant.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, OnDestroy {
  restaurant: Restaurant;
  branches: Branch[];
  selectedBranch?: Branch;
  qrScanner: QrScanner;
  notValid = false;

  constructor(
    private _restaurantService: RestaurantService,
    private _orderService: OrderService,
    private _branchService: BranchService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._restaurantService.restaurant$
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        if (res) this.restaurant = res;
      });

    this._branchService.branches$
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        if (res) {
          this.selectedBranch = res.find(
            (el) => el.id == this.route.snapshot.queryParams['branch']
          );
          if (!this.selectedBranch) this.router.navigate(['']);
        }
      });
  }

  ngOnInit(): void {
    const qrVideoElement = document.getElementById(
      'qrVideo'
    ) as HTMLVideoElement;
    this.qrScanner = new QrScanner(
      qrVideoElement,
      (result) => {
        if (
          result.data &&
          (result.data == 'https://' + this.restaurant.domain.custom_domain ||
            result.data == 'https://' + this.restaurant.domain.order_domain)
        ) {
          this._orderService.orderBranch = this.selectedBranch as Branch;
          this.router.navigate(['']);
          this.qrScanner.stop();
        } else if (
          result.data &&
          result.data != 'https://' + this.restaurant.domain.custom_domain &&
          result.data != 'https://' + this.restaurant.domain.order_domain
        ) {
          this.notValid = true;
          this.cdRef.detectChanges();
        }
      },
      { maxScansPerSecond: 1 }
    );
    this.qrScanner.start();
  }

  ngOnDestroy(): void {
    this.qrScanner.stop();
  }
}
