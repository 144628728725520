export const environment = {
  production: true,
  config: 'prod',

  // API
  API_URL: 'https://api.gofooder.com/storefront/',

  // Google map
  GM_API_KEY: 'AIzaSyA7PZaiXCLEYv87EUljxGz6B4LvM_gku5o',

  // IP Register
  IP_REGISTER_KEY: 'v82jzjew7s5crzgg',

  // MyFatoorah
  MYFATOORAH_APPLE_PAY_SCRIPT: '.myfatoorah.com/applepay/v2/applepay.js',
  MYFATOORAH_CARD_VIEW_SCRIPT: '.myfatoorah.com/cardview/v2/session.js',
};
