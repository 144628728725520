import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { locale as en } from 'src/assets/i18n/en';
import { locale as ar } from 'src/assets/i18n/ar';
import { LoadingScreenService } from './core/services/loading-screen.service';
import { LanguageService } from './core/services/language.service';
import { appAnimations } from './app.animations';
import { LocationService } from './core/services/location.service';
import { RestaurantService } from './core/services/restaurant.service';
import { DocumentService } from './core/services/document.service';
import { BranchService } from './core/services/branches.service';
import { PaymentService } from './core/services/payment.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { UserService } from './core/services/user.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { AnalyticsService } from './core/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [appAnimations],
})
export class AppComponent implements OnInit, OnDestroy {
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  @ViewChild('offlineRef', { static: true }) offlineRef: ElementRef;

  constructor(
    private render: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private deviceDetector: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document,
    private _restaurantService: RestaurantService,
    private _loadScreenService: LoadingScreenService,
    private _languageService: LanguageService,
    private _locationService: LocationService,
    private _documentService: DocumentService,
    private _branchServices: BranchService,
    private _paymentService: PaymentService,
    private _userService: UserService,
    private _localStorageService: LocalStorageService,
    private _analyticsService: AnalyticsService
  ) {
    this.translateService.setTranslation(ar.lang, ar.data);
    this.translateService.setTranslation(en.lang, en.data);
    this.onlineEvent = fromEvent(this.document.defaultView as Window, 'online');
    this.offlineEvent = fromEvent(
      this.document.defaultView as Window,
      'offline'
    );
  }

  ngOnInit(): void {
    this.handleConnectivityChanges();

    this._addScripts();

    this.getRestaurantDetails();
  }

  getRestaurantDetails() {
    this._restaurantService.getRestaurantDetails().subscribe({
      next: async (res) => {
        await this._branchServices.loadBranches(
          res.id,
          !!this.route.snapshot.queryParams['rid']
        );

        this.getUserDetails(res.id);

        this._analyticsService.visit(res.id);

        if (res.payment_suppliers) {
          const myFatoorahSuppliers = res.payment_suppliers.find(
            (el) => el.provider === 'myfatoorah'
          );

          if (myFatoorahSuppliers) {
            this._paymentService
              .initiateMyFatoorahSession()
              .subscribe((res) => {
                this._paymentService.myFatoorahSession = res;
              });
          }
        }
      },
      error: () => {
        this.router.navigate(['error/no-restaurant-found']);
      },
    });
  }

  getUserDetails(businessId: string) {
    setTimeout(() => {
      const rid = this.route.snapshot.queryParams['rid'];
      if (rid) {
        localStorage.removeItem('token');
        this._userService.getRidDetails(rid, businessId).subscribe({
          error: (error) => this._localStorageService.removeItem('token'),
        });
      } else {
        this._userService.getUser();
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    if (this._documentService.isBrowserPlatform())
      return (
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData['animationState']
      );
  }

  private handleConnectivityChanges(): void {
    this.onlineEvent = fromEvent(this.document.defaultView as Window, 'online');
    this.offlineEvent = fromEvent(
      this.document.defaultView as Window,
      'offline'
    );

    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.render.setStyle(this.offlineRef.nativeElement, 'opacity', '0');
        setTimeout(() => {
          this.render.setStyle(
            this.offlineRef.nativeElement,
            'display',
            'none'
          );
        }, 300);
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.render.setStyle(this.offlineRef.nativeElement, 'opacity', '0');
        this.render.setStyle(this.offlineRef.nativeElement, 'display', 'block');
        setTimeout(() => {
          this.render.setStyle(this.offlineRef.nativeElement, 'opacity', '1');
        }, 300);
      })
    );
  }

  private _addScripts() {
    this._locationService.getLocation().subscribe((res) => {
      this._paymentService.loadMyFatoorahCardViewScript();

      if (this.deviceDetector.browser == 'Safari')
        this._paymentService.loadMyFatoorahApplePayScript();

      if (environment.config == 'prod') {
        // add newRelic script
        // const script = this.document.createElement('script');
        // script.type = 'text/javascript';
        // script.async = false;
        // script.src = 'scripts/newrelic_marhaba.js';
        // this.render.appendChild(this.document.body, script);
        // script.onerror = (error: any) =>
        //   console.log('error on loading newRelic script: ', error);
        // add google tag manager script
        // document
        //   .getElementById('googleTagManager')
        //   ?.setAttribute('src', 'scripts/google_tag_manager.js');
        // const noScriptIframe = this.document.createElement('iframe');
        // noScriptIframe.height = '0';
        // noScriptIframe.width = '0';
        // noScriptIframe.setAttribute('style', 'display:none;visibility:hidden');
        // noScriptIframe.src =
        //   'https://www.googletagmanager.com/ns.html?id=GTM-THMFWSRD';
        // document
        //   .getElementById('noScriptGoogleTagManager')
        //   ?.append(noScriptIframe);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
