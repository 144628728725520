import { Injectable } from '@angular/core';
import { map, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ipRegisterResponse } from '../models';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private isMapLoaded: boolean = false;
  private _country: string;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private _localStorageService: LocalStorageService
  ) {}

  loadMapApi() {
    if (this.isMapLoaded) {
      return of(new google.maps.Geocoder());
    } else {
      return this.http
        .jsonp(
          `https://maps.googleapis.com/maps/api/js?key=${environment.GM_API_KEY}&libraries=places&callback=Function.prototype`,
          'callback'
        )
        .pipe(
          tap(() => (this.isMapLoaded = true)),
          map(() => new google.maps.Geocoder())
        );
    }
  }

  getUserLocationDetails() {
    return this.http.get<ipRegisterResponse>(`https://api.ipregistry.co`, {
      params: { key: environment.IP_REGISTER_KEY },
    });
  }

  getLocation() {
    const location = this._localStorageService.getItem('location');
    if (location) {
      this._country = location.country;
      return of(location);
    } else {
      return this.http
        .get<ipRegisterResponse>(`https://api.ipregistry.co`, {
          params: { key: environment.IP_REGISTER_KEY },
        })
        .pipe(
          map((res) => {
            this._country = res.location.country.code.toLowerCase();

            let referrer = window.document.referrer;
            referrer = referrer
              .replace('www.', '')
              .replace('http://', '')
              .replace('https://', '');
            referrer = referrer.substring(0, referrer.indexOf('.'));
            if (!referrer) referrer = 'direct';

            const device = this.deviceService.isDesktop()
              ? 'desktop'
              : this.deviceService.isMobile()
              ? 'mobile'
              : this.deviceService.isTablet()
              ? 'tablet'
              : 'other';

            const os = this.deviceService.os.toLowerCase();

            const payload = {
              city: res.location.city,
              country: res.location.country.code.toLowerCase(),
              referrer: referrer,
              device,
              os,
              lat: res.location.latitude,
              lng: res.location.longitude,
            };

            this._localStorageService.setItem('location', payload);

            return payload;
          })
        );
    }
  }

  get country() {
    return this._country;
  }
}
