import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ToastComponent } from 'src/app/shared/components/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastService: ToastrService,
    private translateService: TranslateService
  ) {}

  public showToast(data: {
    title: string;
    primaryMessage?: string;
    secondaryMessage?: string;
    primaryBtnTxt?: string;
    secondaryBtnTxt?: string;
  }) {
    return this.toastService.show(
      data.primaryMessage
        ? this.translateService.instant(data.primaryMessage)
        : '',
      this.translateService.instant(data.title),
      {
        tapToDismiss: false,
        disableTimeOut: true,
        payload: {
          primaryButtonText: data.primaryBtnTxt
            ? this.translateService.instant(data.primaryBtnTxt)
            : this.translateService.instant('SHARED.OK'),
          secondaryButtonText: data.secondaryBtnTxt
            ? this.translateService.instant(data.secondaryBtnTxt)
            : '',
          secondaryMessage: data.secondaryMessage,
        },
        enableHtml: false,
        toastComponent: ToastComponent,
      }
    ).onAction;
  }
}
