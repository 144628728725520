import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AccessGuard } from './core/helpers/access.guard';
import { QrCodeComponent } from './modules/order-mode/components/qr-code/qr-code.component';
import { PaymentFailedComponent } from './shared/components/payment-failed/payment-failed.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    data: { animationState: 'home' },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { animationState: 'auth' },
  },
  {
    path: 'product/:id',
    loadChildren: () =>
      import('./modules/product/product.module').then((m) => m.ProductModule),
    data: { animationState: 'product' },
  },
  {
    path: 'product/:id/edit/:cartItemIndex',
    loadChildren: () =>
      import('./modules/product/product.module').then((m) => m.ProductModule),
    data: { animationState: 'product' },
    canActivate: [AccessGuard],
  },
  {
    path: 'order-mode',
    loadChildren: () =>
      import('./modules/order-mode/order-mode.module').then(
        (m) => m.OrderModeModule
      ),
    data: { animationState: 'orderMode' },
    canActivate: [AccessGuard],
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./modules/cart/cart.module').then((m) => m.CartModule),
    data: { animationState: 'cart' },
    canActivate: [AccessGuard],
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./modules/checkout/checkout.module').then(
        (m) => m.CheckoutModule
      ),
    data: { animationState: 'checkout' },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
    data: { animationState: 'cart' },
    canActivate: [AccessGuard],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/error/error.module').then((m) => m.ErrorModule),
    data: { animationState: 'error' },
  },
  {
    path: 'qr-code',
    component: QrCodeComponent,
    data: { modeAnimationState: 'qrCode' },
    canActivate: [AccessGuard],
  },
  {
    path: 'status/:orderId',
    loadChildren: () =>
      import('./modules/order-status/order-status.module').then(
        (m) => m.OrderStatusModule
      ),
    data: { animationState: 'status' },
  },
  {
    path: 'rate/:orderId',
    loadChildren: () =>
      import('./modules/order-rate/order-rate.module').then(
        (m) => m.OrderRateModule
      ),
    data: { animationState: 'rate' },
  },
  {
    path: 'payment-link/:orderId',
    loadChildren: () =>
      import('./modules/payment-link/payment-link.module').then(
        (m) => m.PaymentLinkModule
      ),
    data: { animationState: 'payment' },
  },
  {
    path: 'driver/:orderId',
    loadChildren: () =>
      import('./modules/driver/driver.module').then((m) => m.DriverModule),
    data: { animationState: 'driver' },
  },
  {
    path: 'payment-failed',
    component: PaymentFailedComponent,
    data: { modeAnimationState: 'payment-failed' },
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
