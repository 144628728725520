export const locale = {
  lang: 'ar',
  data: {
    CURRENCY: {
      INR: '₹',
      KWD: 'د.ك',
      SAR: 'ر.س',
      GBP: '£',
      EGP: 'ج.م',
      AED: 'د.إ',
      OMR: 'ر.ع',
      BHD: 'د.ب',
      USD: '$',
      QAR: 'ر.ق',
      EUR: '€',
    },
    SHARED: {
      OFFLINE: 'لا يوجد اتصال بالإنترنت',
      POWERED: 'مدعوم بواسطة فودر®',
      ORDER: {
        TYPES: {
          DELIVERY: 'توصيل',
          DINE_IN: 'تناول بالمطعم',
          PICK_UP: 'استلام من الفرع',
          DRIVE_THRU: 'استلام بالسيارة',
        },
        STATUS: {
          PENDING: 'قيد الإنتظار',
          ACCEPTED: 'تم القبول',
          REJECTED: 'تم الرفض',
          CANCELLED: 'تم الإلغاء',
          BEING_PREPARED: 'في المطبخ',
          READY: 'جاهز',
          COMPLETED: 'مكتمل',
          DELIVERED: 'تم التوصيل',
          OUT_FOR_DELIVERY: 'في الطريق إليك',
        },
      },
      DAYS: {
        SATURDAY: 'السبت',
        SUNDAY: 'الأحد',
        MONDAY: 'الأثنين',
        TUESDAY: 'الثلاثاء',
        WEDNESDAY: 'الأربعاء',
        THURSDAY: 'الخميس',
        FRIDAY: 'الجمعة',
      },
      OK: 'حسناً',
      CANCEL: 'إلغاء',
      API_ERROR: 'حدث خطأ, الرجاء المحاولة مرة آخرى',
      SORRY: 'عفوا',
      ERROR: 'خطأ',
      ALERT: 'تنبيه !',
    },
    HOME: {
      MENU: 'قائمة الطعام',
      CALORIES: 'كالوري',
      VIEW_BASKET: 'عرض السلة',
      ORDER_TIME: 'وقت الطلب',
      ASAP: 'أقرب وقت',
      CHANGE: 'تغيير',
      SCHEDULE: 'جدولة',
      SELECT_BRANCH: 'اختر اقرب فرع',
      SELECT_ADDRESS: 'اختر عنوان التوصيل',
      SELECT_TABLE: 'اختر طاولتك',
      SELECT_ITEM: 'تحديد الصنف',
      USE_CURRENT_ITEM: 'هل تريد تكرار الصنف بالخيارات السابقة : ',
      CONTINUE_WITH_CURRENT: 'إضافة صنف بنفس الخيارات السابقة',
      MAKE_NEW_SELECTION: 'تحديد  خيارات جديدة',
      ALERT_CLOSED_UNTIL: 'يفتح المطعم يوم',
      ALERT_CLOSED: 'المطعم مغلق',
      ALERT_SCHEDULABLE: 'الطلب المسبق متاح. يفتح المطعم يوم',
      TABLE: 'طاولة رقم ',
      MINIMUM_CART_ALERT: 'الحد الأدنى لقيمة السلة',
      MOST_ORDERED: 'الأكثر طلبا',
    },
    PRODUCT: {
      CALORIES: 'كالوري',
      OPTIONAL: 'أختياري',
      REQUIRED: 'إجباري',
      CHOOSE_UP_TO: 'أختر حتي',
      CHOOSE_ONLY: 'أختر فقط',
      QUANTITY: 'العدد',
      ADD_BUTTON: 'اضف للسلة',
      ITEMS_INCLUDED: 'عنصر مضاف',
      CHOOSE_AT_LEAST: 'اختر على الأقل',
      ITEM: 'عنصر من',
      ITEMS: 'عناصر من',
      SHARE: ' شارك',
      UPDATE: 'تحديث السلة',
      PRODUCT_NOT_AVAILABLE_BRANCH: 'هذا المنتج غير متاح في الفرع المختار',
      PRODUCT_NOT_AVAILABLE_TIME: 'هذا المنتج غير متاح في الوقت الحالي',
    },
    ORDER_MODE: {
      TITLE: 'وضع الطلب',
      ORDER_TYPE: 'نوع الطلب',
      ORDER_TIME: 'وقت الطلب',
      ASAP: 'في أقرب وقت ممكن',
      SCHEDULE_TIME: 'جدولة وقت الطلب',
      SET_ORDER: 'اضبط الوضع',
      SET_TIME: 'الوقت',
      NO_AVAILABLE_TIME: 'لا يوجد وقت متاح',
      TODAY: 'اليوم',
      AM: 'صباحاً ',
      PM: 'مساءً',
      TIME_VALIDATION: 'من فضلك حدد وقت صحيح للطلب المجدول',
    },
    ADDRESS: {
      TITLE: 'توصيل إلى',
      MY_ADDRESS: 'عناويني',
      QUICK_LOGIN: 'تسجيل الدخول لرؤية عناوينك ',
      LOGIN_BY_WA: 'تسجيل الدخول عن طريق الواتساب',
      ADD_ADDRESS: '+ إضافة عنوان جديد',
      CHOOSE_ADDRESS: 'اختر عنواني',
      CREATE_ADDRESS: 'انشاء عنوان',
      EDIT_LOCATION: 'تعديل العنوان من الخريطة',
      ADDRESS_DETAILS: 'تفاصيل العنوان',
      FULL_ADDRESS: 'العنوان بالكامل *',
      ADDITIONAL_ADDRESS: 'شقة أو فيلا أو رقم الطابق *',
      DELIVERY_INSTRUCTION: 'تعليمات التوصيل *',
      DELIVERY_INSTRUCTION_EG: 'علي سبيل المثال: لا ترن الجرس',
      ADDRESS_NAME: 'اسم العنوان *',
      HOME: 'المنزل',
      OFFICE: 'المكتب',
      OTHER: 'آخرى',
      SAVE_ADDRESS: 'احفظ العنوان',
      CANT_SEREVE: 'عذرا، لا يمكننا خدمتك في الوقت الحالي',
      CANT_SERVE_DESCRIPTION:
        'الفروع غير متوفرة للعنوان المختار  في الوقت الحالي',
      NO_ADDRESS_AVAILABLE: 'لا يوجد عناوين محفوظة',
    },
    MAP: {
      TITLE: 'إضافة عنوان جديد',
      SET_DELIVERY: 'تحديد عنوان التوصيل',
      MARK_LOCATION:
        'حدد الموقع بشكل دقيق لمساعدة سائقنا على الإنجاز بشكل أسرع',
      SEARCH: 'البحث عن العنوان',
      CHOOSE_ADDRESS: 'اختر عنواني',
      LOCATION_OFF: 'موقع الجهاز متوقف',
      TURN_LOCATION_ON:
        'سيؤدي تشغيل موقع الجهاز إلى ضمان العنوان الدقيق والتوصيل الخالي من المتاعب.',
      LOCATE_MANUAL: 'تحديد موقع يدويا',
      NOT_THERE_YET: 'نحن لا نخدم هذه المنطقة حتى الأن',
      NOT_THERE_YET_DESCRIPTION:
        'موقع التسليم لا يزال خارج منطقة التسليم لدينا. ومع ذلك، يمكنك تجربة موقع مختلف أو تحديد خيار الاستلام للحصول على طلبك في المنفذ.',
      CHANGE_LOCATION: 'تغيير الموقع',
    },
    BRANCHES: {
      TITLE: 'اختر الفرع',
      AVAILABLE: 'الفروع المتاحة',
      CLOSED: 'مغلق',
      PREORDER: 'مجدول',
      SET_BRANCH: 'اختر الفرع',
      CLOSED_UNTIL: 'مغلق حتى',
      OPEN_UNTIL: 'مفتوح حتى',
      NO_BRANCHES_AVAILABLE: 'لا توجد فروع متاحة',
      BRANCH_NOT_AVAILABLE: 'الفرع غير متاح حاليا',
      KM: 'كم',
      M: 'م',
    },
    LOGIN: {
      TITLE: 'تسجيل الدخول',
      ENTER_YOUR: ' ادخل رقم',
      WHATSAPP: 'الواتساب',
      DESCRIPTION:
        'قم بتسجيل الدخول باستخدام رقم الواتساب الخاص بك للاستفادة من العروض وتلقي التحديثات على طلباتك',
      WHATSAPP_NUMBER: 'رقم الواتساب',
      ACCEPT: 'بالضغط فوق إرسال ، أوافق على ',
      TERMS: 'الشروط والأحكام',
      AND: 'و',
      PRIVACY: 'سياسة الخصوصية',
      CHOOSE_COUNTRY: 'اختر دولتك',
      NUMBER_WRONG: 'رقم غير صحيح',
      NUMBER_REQUIRED: 'الرقم مطلوب',
      SEARCH_HERE: 'ابحث هنا',
      OTP_SEND_ERROR: 'حدث خطأ أثناء إرسال رمز التحقق.',
      OTP_TIME_ERROR:
        'تم إرسال رمز التحقق بالفعل ، يرجى الانتظار دقيقة والمحاولة مرة أخرى',
      VERIFY_WHATSAPP: 'التأكد من رقم الواتساب الخاص بك',
      OTP_SENT_DESCRIPTION:
        'لقد أرسلنا إليك رسالة علي الواتساب تحتوي على رمز التحقق إلى ',
      NOT_RECEIVED: 'لم تسلم رمز التحقق حتى الأن',
      RESEND: '( اعادة ارسال )',
      INVALID_CODE: 'رمز تححق خاطئ.',
      WELCOME: 'أهلا 👋',
      SHARE_NAME: 'يرجى مشاركة اسمك معنا',
      YOUR_NAME: 'اسمك',
      TYPE_FULLNAME: 'ادخل اسمك بالكامل هنا',
      NAME_VALIDATION_REQUIRED: 'الاسم مطلوب',
      NAME_VALIDATION_WRONG: 'ادخل اسم صحيح',
      SEC: 'ثانية',
      SAVE: 'حفظ',
      OK: 'حسناٌ',
    },
    ERROR: {
      TITLE_RESTAURANT: 'المطعم غير موجود',
      TITLE_PAGE: 'الصفحة غير موجودة',
      DESCRIPTION: 'الرابط المطلوب غير موجود على هذا الموقع',
      BACK_TO_HOME: 'العودة للصفحة الرئيسية',
      BANK_DECLINED: 'رفض البنك الخاص بكم المعاملة',
      LINK_EXPIRED: 'انتهت مدة صلاحية',
      LINK_EXPIRED_DESCRIPTION: 'انتهت مدة صلاحية الرابط المطلوب',
    },
    QR_CODE: {
      TITLE: 'امسح QR code',
      DESCRIPTION: 'من فضلك امسح ال QR code من الطاولة',
      WRONG_ALERT: 'QR code غير صحيح',
    },
    TABLE_NUMBER: {
      TITLE: 'رقم الطاولة',
      DESCRIPTION: 'اضف رقم الطاولة',
      ADD_TABLE: 'اضف الطاولة',
      VALIDATION_MSG: 'رقم الطاولة مطلوب',
    },
    CART: {
      TITLE: 'أكمل طلبك',
      EDIT_ITEM: 'تعديل العنصر',
      MY_BASKET: 'سلتي',
      SPECTIAL_INSTRUCTION: 'تعليمات خاصة',
      WITHOUT_BBQ: 'بدون باربيكيو',
      EDIT: 'تعديل',
      TABLE: 'طاولة رقم ',
      CHECKOUT: 'الدفع',
      ITEMS_INCLUDED: 'عنصر مضاف',
      START_NEW_BASKET: 'ابدأ سلة جديدة؟',
      CLEAR_BASKET: 'سيؤدي هذا إلى مسح سلتك',
      START: 'البدأ',
      CANCEL: 'إلغاء',
      CLEAR_CART: 'مسح السلة',
      CLEAR_CART_DESC: 'سيتم مسح جميع محتويات السلة',
      CLEAR: 'مسح',
      SET_OPTIONS: 'حدد الإضافات',
      CART_CHANGED: 'لقد تغيرت سلة التسوق وفقا للفرع المحدد',
    },
    CHECKOUT: {
      TITLE: 'الدفع',
      DELIVERY_TO: 'توصيل إلى',
      PICKUP_FROM: 'الإستلام من',
      CAR_PICKUP_FROM: 'الإستلام بالسيارة من',
      DINING_AT: 'تناول في',
      CHANGE: 'تغيير',
      DELIVERY_TIME: 'وقت التوصيل',
      PICKUP_TIME: 'وقت الإستلام',
      DINING_TIME: 'وقت التناول بالمطعم',
      CAR_PICKUP_TIME: 'وقت الإستلام بالسيارة',
      ASAP: 'في أقرب وقت ممكن',
      APPLY_COUPON: 'تطبيق الكوبون',
      APPLY: 'تطبيق',
      REMOVE: 'حذف',
      VALID_TO: 'صالح حتى',
      PAYMENT_TYPE: 'نوع الدفع',
      PAY_ONLINE: 'الدفع اونلاين',
      PAY_WITH: 'الدفع بواسطة',
      CASH_PICKUP: 'الدفع عند الإستلام',
      CASH_DELIVERY: 'الدفع عند التوصيل',
      CASH_CAR_PICKUP: 'الدفع عند الإستلام بالسيارة',
      CASH_DINEIN: 'الدفع عند التانول بالمطعم',
      ONLINE: 'اونلاين',
      ONLINE_PAYMENT: 'الدفع اونلاين',
      PAY_WITH_CREDIT: 'ادفع باستخدام بطاقتك المصرفية',
      POWERED_BY: 'مدعوم بواسطة',
      PAY_NOW: 'أدفع الأن',
      TRANSACTION_DECLINED: 'تم رفض المعاملة',
      TRANSACTION_DECLINED_DESC:
        'عذرًا، لقد تم رفض معاملتك، حاول الاتصال بالبنك الذي تتعامل معه أو تغيير بطاقة الائتمان',
      ORDER_PLACE_FAILED: 'حدث خطأ اثناء ارسال الطلب',
      COUPON_PERCENTAGE_DESC:
        'استخدم {{code}} واحصل علي {{amount}}% حتى {{max}}{{currency}} على اي طلب فوق {{min}} {{currency}}',
      COUPON_FIXED_DESC:
        'استخدم {{code}} واحصل على {{amount}} {{currency}} على اي طلب فوق {{min}} {{currency}}',
      INVALID_COUPON: 'ادخل كوبون صحيح',
      WRONG_COUPON: 'كوبون خاطئ',
      WRONG_COUPON_DESC: 'لم نعثر علي الكوبون, حاول مرة آخرى',
      NOT_APPLICABLE_DESC: 'هذا الكوبون غير قابل للتطبيق  علي هذا الطلب.',
      BILL_DETAILS: 'تفاصيل الفاتورة',
      SUB_TOTAL: 'المجموع الفرعي',
      ITEM_VAT: 'الضريبة (%{{amount}})',
      DISCOUNT: 'الخصم %',
      DELIVERY_FEES: 'رسوم التوصيل',
      DELIVERY_VAT: 'ضريبة التوصيل (%{{amount}})',
      GRAND_TOTAL: 'إجمالي الفاتورة',
      VAT: '(شاملة القيمة المضافة)',
      GST: '(شاملة القيمة المضافة)',
      TOTAL_ORDER_AMOUNT: 'إجمالي قيمة الطلب',
      PLACE_ORDER: 'أكمل الطلب',
      BRANCH_MUST_SELECTED: 'يجب اختيار الفرع',
      TABLE_NUBER_MUST_SET: 'يجب ادخال رقم الطاولة',
      ADDRESS_MUST_SELECTED: 'يجب ان تختار عنوان للتوصيل',
      OR_CANCEL: 'أو الغاء الطلب',
      MAXIMUM_CASH_ALERT: 'الحد الاقصى للدفع النقدي المسموح',
      ERRORS: {
        MAXIMUM_USE_PER_CUSTOMER:
          'لقد تجاوزت الحد الأقصى للاستخدام لكل عميل لهذا الكوبون',
      },
    },
    ACCOUNT: {
      PROFILE: 'حسابي',
      HEY: 'اهلا',
      LOGOUT: 'تسجيل خروج',
      DETAILS: 'تفاصيل الحساب',
      MY_ORDERS: 'طلباتي',
      SUPPORT: 'الدعم',
      PRIVACY_POLICY: 'سياسة الاستخدام',
      MY_ACCOUNT: 'حسابي',
      UPDATE_CONNECT: 'تحديث طريقة الاتصال',
      NAME: 'الاسم',
      EMAIL: 'الايميل',
      PHONE_NUMBER: 'رقم الهاتف',
      WHATSAPP_NUMBER: 'رقم الواتساب',
      SAVE: 'حفظ',
      ORDER: 'طلب رقم',
      RE_ORDER: 'اعادة طلب',
      ORDER_TYPE: 'نوع الطلب',
      ORDER_DETAILS: 'بيانات الطلب',
      REVIEW_ORDER: 'تقييم الطلب',
      NAME_REQUIRED: 'الاسم مطلوب',
      EMAIL_REQUIRED: 'الايميل مطلوب',
      EMAIL_INVALID: 'الايميل غير صحيح',
      PHONE_REQUIRED: 'الرقم مطلوب',
      PHONE_INVALID: 'رقم غير صحيح',
      DATA_CHANGED: 'تم تغيير البيانات بنجاح',
    },
    STATUS: {
      TITLE: 'تتبع طلبك',
      ORDER: 'طلب رقم',
      RE_ORDER: 'اعادة طلب',
      PAYMENT_TYPE: 'طريقة الدفع',
      ORDER_TYPE: 'نوع الطلب',
      VIEW_RECIEPT: 'عرض الإيصال',
      DELIVERY_TO: 'توصيل إلى',
      PICKUP_FROM: 'استلام من',
      CAR_PICKUP_FROM: 'استلام بالسيارة من',
      DINING_AT: 'تناول في',
      DELIVERY_TIME: 'وقت التوصيل',
      PICKUP_TIME: 'وقت الاستلام',
      DINING_TIME: 'وقت التناول بالمطعم',
      CAR_PICKUP_TIME: 'وقت الاستلام بالسيارة',
      ASAP: 'في اسرع وقت ممكن',
      CASH_PICKUP: 'الدفع عند الاستلام',
      CASH_DELIVERY: 'الدفع عند التوصيل',
      CASH_CAR_PICKUP: 'الدفع عند الاستلام بالسيارة',
      CASH_DINEIN: 'الدفع عند التناول بالمطعم',
      ONLINE: 'اونلاين',
      PENDING_DESC: 'لقد تم ارسال طلبك في {{time}}',
      PENDING_DESC_2: 'في انتظار التأكيد من الفرع',
      ACCEPTED_DESC: 'تم تأكيد الطلب',
      BEING_PREPARED_DESC: 'يتم تجهيز طلبك ',
      PICKUP_READY_DESC: 'جاهز للأستلام',
      DINEIN_READY_DESC: 'جاهز للتناول في المطعم',
      CANCELLED_DESC: 'تم الغاء طلبك',
      REJECTED_DESC: 'تم رفض طلبك',
      OUT_FOR_DELIVERY_DESC: 'طلبك في الطريق إليك',
      REVIEW_ORDER: 'قيم الطلب',
      DELIVER_TO_CAR: 'تسليم إلى سيارتك',
      DELIVER_TO_CAR_DESC: 'أبلغنا عند وصولك إلى فرعنا',
      ARRIVED_TO_BRANCH: 'وصلت الفرع',
      UPDATE_CAR_DETAILS: 'تعديل بيانات السيارة',
      ENTER_VEHICLE: 'ادخل بيانات السيارة',
      VEHICLE_NUMBER: 'رقم السيارة',
      VEHICLE_NUMBER_REQUIRED: 'رقم السيارة مطلوب',
      VEHICLE_COLOR: 'لون السيارة',
      VEHICLE_COLOR_REQUIRED: 'لون السيارة مطلوب',
      VEHICLE_MODEL: 'نوع السيارة',
      VEHICLE_MODEL_REQUIRED: 'نوع السيارة مطلوب',
      NEAR_LANDMARK: 'علامة مميزة قريبة',
      NEAR_LANDMARK_REQUIRED: 'علامة مميزة قريبة مطلوبة',
      SUBMIT: 'حفظ',
    },
    RATE: {
      TITLE: 'قيم طلبك',
      OVERALL_RATE: 'التقييم العام',
      FOOD_QUALITY: 'جودة الطعام',
      SERVICE_LEVEL: 'مستوى الخدمة',
      CLEANNESS: 'النظافة',
      STAFF: 'طاقم العمل',
      OVERALL_COMMENTS: 'تعليقك',
      ADD_NOTE: 'أضف ملحوظة',
      ADD_REVIEW: 'اضف التعليق',
      THANKS_FOR_RATING: 'شكرا لتقييمك',
      THANKS_FOR_RATING_DESC: 'يرجى مشاركة تقييمك علي جوجل ماب',
      BACK_HOME: 'العودة للرئيسية',
      SHARE_REVIEW: 'شارك تقييمك',
    },
    DRIVER: {
      TITLE: 'طلب توصيل',
      HEY: 'أهلا ',
      HAVE_DELIVERY_ORDER: 'لديك طلب توصيل',
      ORDER_NUM: 'طلب رقم ',
      PAYMENT_TYPE: 'طريقة الدفع',
      PICKUP_FROM: 'استلام من',
      GET_DIRECTION: 'اعرض الاتجاهات',
      CLIENT_DETAILS: 'تفاصيل العميل',
      CALL_CLIENT: 'اتصل بالعميل',
      DELIVERY_FEES: 'رسوم التوصيل',
      CLIENT_PAY: 'العميل سوف يدفع',
      ACCEPT: 'اقبل الطلب',
      DELIVERED: 'تم التوصيل',
      NOT_AVAILABLE: 'لا يوجد طلبات متوفرة',
      SORRY: 'عذرا',
      SORRY_DESCRIPTION:
        'عذرًا، لقد تم تعيين طلبك لشخص آخر، حاول أن تكون سريعًا.',
    },
  },
};
