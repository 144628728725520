import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

import { Language } from '../models';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private renderer: Renderer2;
  public language$: Observable<Language>;
  private languageSubject: BehaviorSubject<Language>;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private _translateService: TranslateService,
    private _localStorageService: LocalStorageService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    const language = this._localStorageService.getItem('language') || 'ar';
    this.languageSubject = new BehaviorSubject<Language>(language);
    this.language$ = this.languageSubject.asObservable();
    this.setLanguage(language);
  }

  changeLanguage() {
    this.renderer.setStyle(
      this.document.body,
      'transition',
      'all .2s ease-in-out'
    );
    this.renderer.setStyle(this.document.body, 'opacity', 0);
    setTimeout(() => {
      if (this.language == 'ar') this.setLanguage('en');
      else this.setLanguage('ar');
      this.renderer.setStyle(this.document.body, 'opacity', 1);
    }, 200);
  }

  setLanguage(lang: Language) {
    setTimeout(() => {
      this._translateService.use(lang);
    });
    this._localStorageService.setItem('language', lang);
    this.languageSubject.next(lang);
    if (lang == 'ar') this.document.dir = 'rtl';
    else this.document.dir = 'ltr';
  }

  get language() {
    return this.languageSubject.value;
  }
}
