import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  UserAddress,
  OrderType,
  OrderCheckoutDto,
  Coupon,
  Order,
  PreviousOrdersResponse,
  CreateReviewDto,
  OrderChannel,
  CarSpecs,
} from '../models';
import { Branch } from '../models/branch.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  orderChannel: OrderChannel = 'storefront';

  private orderTypeSubject: BehaviorSubject<OrderType | null>;
  public orderType$: Observable<OrderType | null>;

  private orderTimeSubject: BehaviorSubject<'asap' | Date>;
  public orderTime$: Observable<'asap' | Date>;

  private orderBranchSubject: BehaviorSubject<null | Branch>;
  public orderBranch$: Observable<null | Branch>;

  private orderAddressSubject: BehaviorSubject<UserAddress | null>;
  public orderAddress$: Observable<UserAddress | null>;

  private orderTableSubject: BehaviorSubject<string | null>;
  public orderTable$: Observable<string | null>;

  private couponSubject: BehaviorSubject<Coupon | null>;
  public coupon$: Observable<Coupon | null>;

  constructor(private http: HttpClient) {
    this.orderTypeSubject = new BehaviorSubject<OrderType | null>(null);
    this.orderType$ = this.orderTypeSubject.asObservable();

    this.orderTimeSubject = new BehaviorSubject<'asap' | Date>('asap');
    this.orderTime$ = this.orderTimeSubject.asObservable();

    this.orderAddressSubject = new BehaviorSubject<UserAddress | null>(null);
    this.orderAddress$ = this.orderAddressSubject.asObservable();

    this.orderBranchSubject = new BehaviorSubject<Branch | null>(null);
    this.orderBranch$ = this.orderBranchSubject.asObservable();

    this.orderTableSubject = new BehaviorSubject<string | null>(null);
    this.orderTable$ = this.orderTableSubject.asObservable();

    this.couponSubject = new BehaviorSubject<Coupon | null>(null);
    this.coupon$ = this.couponSubject.asObservable();
  }

  create(branchId: string, body: OrderCheckoutDto, tracking_id?: string) {
    if (tracking_id) {
      return this.http.post<{
        data: { order_id: string; payment_url: null | string };
      }>(environment.API_URL + 'branches/' + branchId + '/checkout', body, {
        headers: {
          tracking_id,
        },
      });
    } else {
      return this.http.post<{
        data: { order_id: string; payment_url: null | string };
      }>(environment.API_URL + 'branches/' + branchId + '/checkout', body);
    }
  }

  getDetails(orderId: string, include: string) {
    return this.http
      .get<{ data: Order }>(environment.API_URL + 'orders/' + orderId, {
        params: { include },
      })
      .pipe(map((res) => res.data));
  }

  getPreviousOrders(
    businessId: string,
    pagination: { limit: number; page: number }
  ) {
    return this.http.get<PreviousOrdersResponse>(
      environment.API_URL + 'customers/businesses/' + businessId + '/orders',
      {
        params: { ...pagination },
      }
    );
  }

  setCarDetails(orderId: string, data: CarSpecs) {
    return this.http
      .put<{ data: Order }>(environment.API_URL + 'orders/' + orderId, {
        ...data,
      })
      .pipe(map((res) => res.data));
  }

  submitReview(orderId: string, body: CreateReviewDto) {
    return this.http.post<PreviousOrdersResponse>(
      environment.API_URL + 'orders/' + orderId + '/review',
      body
    );
  }

  clearSelectedData() {
    this.orderAddressSubject.next(null);
    this.orderTimeSubject.next('asap');
    this.orderBranchSubject.next(null);
    this.orderTableSubject.next(null);
  }

  set orderType(type: OrderType) {
    this.orderTypeSubject.next(type);
  }

  set orderTime(time: 'asap' | Date) {
    this.orderTimeSubject.next(time);
  }

  set orderBranch(branch: Branch | null) {
    this.orderBranchSubject.next(branch);
  }

  set orderAddress(address: UserAddress | null) {
    this.orderAddressSubject.next(address);
  }

  set orderTable(number: string) {
    this.orderTableSubject.next(number);
  }

  set coupon(coupon: Coupon | null) {
    this.couponSubject.next(coupon);
  }

  get orderType() {
    return this.orderTypeSubject.value || 'unset';
  }
  get orderTime() {
    return this.orderTimeSubject.value;
  }
  get orderBranch() {
    return this.orderBranchSubject.value;
  }

  get coupon() {
    return this.couponSubject.value;
  }
}
