import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import {
  CategoriesResponse,
  Category,
  ProductResponse,
  Product,
  ProductsResponse,
} from '../models/menu.model';
import { OrderType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public menu$: Observable<Category[] | null>;
  private menuSubject: BehaviorSubject<Category[] | null>;
  public bestSeller$: Observable<Product[] | null>;
  private bestSellerSubject: BehaviorSubject<Product[] | null>;

  constructor(private http: HttpClient) {
    this.menuSubject = new BehaviorSubject<Category[] | null>(null);
    this.menu$ = this.menuSubject.asObservable();

    this.bestSellerSubject = new BehaviorSubject<Product[] | null>(null);
    this.bestSeller$ = this.bestSellerSubject.asObservable();
  }

  loadMenu(
    businessId: string,
    params?: { ordering_service: OrderType; branch_id: string }
  ) {
    return this.http
      .get<CategoriesResponse>(
        environment.API_URL + 'businesses/' + businessId + '/menus',
        { params }
      )
      .subscribe((res) => {
        const menu = this.handleCustomPriceAndInactiveBranch(
          res.data.categories,
          params?.branch_id
        );
        this.menuSubject.next(menu);
        this.bestSellerSubject.next(this._getBestSellerProducts(menu));

        return menu;
      });
  }

  getProductsByIds(businessId: string, productsIds: string, branchId: string) {
    return this.http
      .get<ProductsResponse>(
        environment.API_URL + 'businesses/' + businessId + '/menus/products',
        {
          params: { productsIds, branchId },
        }
      )
      .pipe(map((res) => res.data));
  }

  getProduct(businessId: string, productId: string, branchId?: string) {
    const params: { include: string; branch_id?: string } = {
      include: 'modifiers,options,branches',
    };
    if (branchId) params['branch_id'] = branchId;

    return this.http
      .get<ProductResponse>(
        environment.API_URL +
          'businesses/' +
          businessId +
          '/menus/' +
          productId,
        {
          params,
        }
      )
      .pipe(map((res) => res.data));
  }

  get menu() {
    return this.menuSubject.value;
  }

  handleCustomPriceAndInactiveBranch(menu: Category[], branchId?: string) {
    const filteredMenu: Category[] = [];

    menu.forEach((category) => {
      category.products.forEach((product) => {
        if (product.branches) {
          product.branches.forEach((productBranch: any) => {
            if (!productBranch) {
              const index = filteredMenu.findIndex(
                (filteredCategory) => filteredCategory.id == category.id
              );
              if (index >= 0) {
                filteredMenu[index].products.push(product);
              } else {
                filteredMenu.push({
                  id: category.id,
                  name: category.name,
                  name_localized: category.name_localized,
                  products: [product],
                });
              }
              return;
            }

            if (
              productBranch &&
              branchId &&
              productBranch.status == 'active' &&
              productBranch.custom_price &&
              productBranch.branch_id == branchId
            ) {
              const index = filteredMenu.findIndex(
                (filteredCategory) => filteredCategory.id == category.id
              );
              if (index >= 0) {
                filteredMenu[index].products.push({
                  ...product,
                  price: productBranch.custom_price,
                });
              } else {
                filteredMenu.push({
                  id: category.id,
                  name: category.name,
                  name_localized: category.name_localized,
                  products: [{ ...product, price: productBranch.custom_price }],
                });
              }
            }
          });
        }
      });
    });

    return filteredMenu;
  }

  private _getBestSellerProducts(menu: Category[]) {
    const mostOrderedItems: Product[] = [];

    menu.forEach((category) => {
      category.products.forEach((product) => {
        if (product.best_seller) mostOrderedItems.push(product);
      });
    });

    return mostOrderedItems;
  }
}
