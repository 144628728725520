import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import {
  ExecutePaymentDto,
  InitiateSessionResponse,
  MyFatoorahSession,
} from '../models/payment.model';
import { DOCUMENT } from '@angular/common';
import { Order } from '../models';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  myFatoorahSession: MyFatoorahSession | null;
  private renderer: Renderer2;

  constructor(
    private http: HttpClient,
    rendererFactory: RendererFactory2,
    private _localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  getPaymentLink(orderId: string, include: string) {
    return this.http
      .get<{ data: Order }>(
        environment.API_URL + 'orders/' + orderId + '/payment-links',
        { params: { include } }
      )
      .pipe(map((res) => res.data));
  }

  executePaymentLink(orderId: string, data: ExecutePaymentDto) {
    return this.http.post<{ data: { order_id: String; payment_url: string } }>(
      environment.API_URL + 'orders/' + orderId + '/execute-payment',
      { draft: data }
    );
  }

  initiateMyFatoorahSession() {
    return this.http
      .post<InitiateSessionResponse>(
        environment.API_URL + 'payments/session',
        {}
      )
      .pipe(map((res) => res.data));
  }

  loadMyFatoorahCardViewScript() {
    // generate script src according too environment and country
    let scriptSrc = '';
    if (environment.config == 'prod') {
      const country = this._localStorageService.getItem('country') || 'sa';

      if (country == 'sa')
        scriptSrc = 'https://sa' + environment.MYFATOORAH_CARD_VIEW_SCRIPT;
      else if (country == 'qa')
        scriptSrc = 'https://qa' + environment.MYFATOORAH_CARD_VIEW_SCRIPT;
      else
        scriptSrc = 'https://portal' + environment.MYFATOORAH_CARD_VIEW_SCRIPT;
    } else scriptSrc = 'https://demo' + environment.MYFATOORAH_CARD_VIEW_SCRIPT;

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = scriptSrc;

    this.renderer.appendChild(this.document.body, script);

    script.onerror = (error: any) =>
      console.log('error on loading card view script: ', error);
  }

  loadMyFatoorahApplePayScript() {
    // generate script src according too environment and country
    let scriptSrc = '';
    if (environment.config == 'prod') {
      const country = this._localStorageService.getItem('country') || 'sa';
      if (country == 'sa')
        scriptSrc = 'https://sa' + environment.MYFATOORAH_APPLE_PAY_SCRIPT;
      else if (country == 'qa')
        scriptSrc = 'https://qa' + environment.MYFATOORAH_APPLE_PAY_SCRIPT;
      else
        scriptSrc = 'https://portal' + environment.MYFATOORAH_APPLE_PAY_SCRIPT;
    } else scriptSrc = 'https://demo' + environment.MYFATOORAH_APPLE_PAY_SCRIPT;

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = scriptSrc;

    this.renderer.appendChild(this.document.body, script);

    script.onerror = (error: any) =>
      console.log('error on loading apple pay script: ', error);
  }
}
