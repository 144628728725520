import {
  transition,
  trigger,
  style,
  animate,
  query,
} from '@angular/animations';

export const appAnimations = trigger('appAnimations', [
  transition('home => error', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        overflow: 'hidden',
      }),
    ]),
    query(':enter', [
      style({ 'z-index': 10, opacity: 0 }),
      animate('150ms ease-in', style({ opacity: 1 })),
    ]),
  ]),

  transition('error => home', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        overflow: 'hidden',
      }),
    ]),
    query(
      ':leave',
      [
        style({ 'z-index': 10 }),
        animate('150ms ease-in', style({ 'z-index': 5, opacity: 0 })),
      ],
      { optional: true }
    ),
  ]),

  transition('* => home', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          overflow: 'hidden',
        }),
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ 'z-index': 10 }),
        animate(
          '150ms ease-in',
          style({ transform: 'translateY(50px)', 'z-index': 5, opacity: 0 })
        ),
      ],
      { optional: true }
    ),
  ]),

  transition('* => *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          overflow: 'hidden',
        }),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({ transform: 'translateY(50px)', 'z-index': 10, opacity: 0 }),
        animate(
          '150ms ease-in',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ],
      { optional: true }
    ),
  ]),
]);
