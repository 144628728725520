import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { isWithinInterval } from 'date-fns';

import {
  APIBranch,
  Branch,
  GetDeliveryBranchResponse,
  MappedOpeningHours,
} from '../models/branch.model';

import { OrderService } from './order.service';
import { CartService } from './cart.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastService } from './toast.service';
import { OrderType } from '../models';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from './location.service';

const days = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private branchesSubject: BehaviorSubject<Branch[] | null>;
  public branches$: Observable<Branch[] | null>;

  private _selectedDeliveryZone: string;
  private _distanceToBranch = 0;

  // to be used for rid for selected branch on get rid details
  public selectedBranchId = '';
  private _orderTypeAvailability: { [key: string]: boolean } = {
    pick_up: false,
    delivery: false,
    dine_in: false,
    drive_thru: false,
  };

  constructor(
    private http: HttpClient,
    private deviceDetectorService: DeviceDetectorService,
    private route: ActivatedRoute,
    private _orderService: OrderService,
    private _cartService: CartService,
    private _toastService: ToastService,
    private _locationService: LocationService
  ) {
    this.branchesSubject = new BehaviorSubject<Branch[] | null>(null);
    this.branches$ = this.branchesSubject.asObservable();
  }

  async loadBranches(businessId: string, whatsappRedirect = false) {
    try {
      const coords = await firstValueFrom(this._getLocation());
      return await firstValueFrom(
        this._loadBranches(businessId, coords.latitude, coords.longitude)
      );
    } catch (err) {
      return await firstValueFrom(this._loadBranches(businessId));
    }
  }

  getDeliveryBranch(businessId: string, lat: string, lng: string) {
    return this.http
      .get<GetDeliveryBranchResponse>(
        environment.API_URL +
          'businesses/' +
          businessId +
          '/branch-by-coordinates',
        {
          params: {
            lat,
            lng,
          },
        }
      )
      .pipe(
        map((res) => {
          if (res.data) {
            if (!this.checkForAvailablePaymentForBranch(res.data.branch)) {
              throw new Error();
            }
            this._orderService.orderBranch = res.data.branch;
            this._selectedDeliveryZone = res.data.selected_zone.id;
            this._distanceToBranch = res.data.distance;
            this._cartService.addDeliveryFees(
              res.data.distance,
              res.data.selected_zone
            );
          }
          return res.data;
        })
      );
  }

  get selectedDeliveryZone() {
    return this._selectedDeliveryZone;
  }

  set selectedDeliveryZone(zoneId: string) {
    this._selectedDeliveryZone = zoneId;
  }

  get distanceToBranch() {
    return this._distanceToBranch;
  }

  get branches() {
    return this.branchesSubject.value || [];
  }

  get orderTypeAvailability() {
    return this._orderTypeAvailability;
  }

  checkForAvailablePaymentForBranch(branch: Branch) {
    let isAvailable = false;

    branch.payment_methods.forEach((payment) => {
      if (
        payment.slug == 'apple_pay' &&
        payment.is_active &&
        this.deviceDetectorService.browser == 'Safari'
      )
        isAvailable = true;
      if (payment.slug != 'apple_pay' && payment.is_active) isAvailable = true;
    });

    return isAvailable;
  }

  selectAvailableOrderType() {
    for (const orderType in this._orderTypeAvailability) {
      if (this._orderTypeAvailability[orderType]) {
        this._orderService.orderType = orderType as OrderType;
        break;
      }
    }
  }

  private checkForAvailableOrderTypes(branches: Branch[]) {
    branches.forEach((branch) => {
      branch.ordering_services.forEach((service) => {
        if (
          service.status == 'active' ||
          (service.status == 'paused' &&
            service.paused_until &&
            new Date(service.paused_until) < new Date())
        ) {
          this._orderTypeAvailability[service.slug] = true;
        }
      });
    });

    this.selectAvailableOrderType();
  }

  private _mapBranches(branches: APIBranch[]) {
    let currentDate = new Date();
    const mappedBranches: Branch[] = [];

    branches.forEach((branchItem) => {
      branchItem.isAvailableNow = false;

      // modify close and open time for each branch to actual date time
      for (let i = 0; i < branchItem.opening_hours.length; i++) {
        let openTime = new Date();
        let closeTime = new Date();

        let [openHours, openMinutes] = (
          branchItem.opening_hours[i].open_time as string
        ).split(':');
        let [closeHours, closeMinutes] = (
          branchItem.opening_hours[i].close_time as string
        ).split(':');

        if (
          currentDate.getDay() >
          days.indexOf(branchItem.opening_hours[i].day_of_week)
        ) {
          openTime.setDate(
            currentDate.getDate() +
              7 -
              currentDate.getDay() +
              days.indexOf(branchItem.opening_hours[i].day_of_week)
          );
          openTime.setHours(+openHours, +openMinutes, 0, 0);

          closeTime.setDate(
            currentDate.getDate() +
              7 -
              currentDate.getDay() +
              days.indexOf(branchItem.opening_hours[i].day_of_week)
          );
          closeTime.setHours(+closeHours, +closeMinutes, 0, 0);
        } else {
          openTime.setDate(
            currentDate.getDate() +
              days.indexOf(branchItem.opening_hours[i].day_of_week) -
              currentDate.getDay()
          );
          openTime.setHours(+openHours, +openMinutes, 0, 0);

          closeTime.setDate(
            currentDate.getDate() +
              days.indexOf(branchItem.opening_hours[i].day_of_week) -
              currentDate.getDay()
          );
          closeTime.setHours(+closeHours, +closeMinutes, 0, 0);
        }

        branchItem.opening_hours[i].open_time = openTime;
        branchItem.opening_hours[i].close_time = closeTime;

        if (
          isWithinInterval(new Date(), {
            start: branchItem.opening_hours[i].open_time as Date,
            end: branchItem.opening_hours[i].close_time as Date,
          }) &&
          branchItem.opening_hours[i].is_active
        )
          branchItem.isAvailableNow = true;
      }

      mappedBranches.push({
        ...branchItem,
        opening_hours: this.formatOpeningHours(branchItem),
      });
    });

    return mappedBranches;
  }

  private formatOpeningHours(branch: APIBranch) {
    const formattedHours = branch.opening_hours.reduce(
      (grouped: MappedOpeningHours[], timeSlot: any) => {
        let existingItem = grouped.find(
          (i) =>
            i.day_of_week === timeSlot.day_of_week &&
            i.is_active === timeSlot.is_active
        );
        if (existingItem) {
          if (existingItem.config[0].open_time < timeSlot.open_time)
            existingItem.config.push({
              open_time: timeSlot.open_time,
              close_time: timeSlot.close_time,
            });
          else
            existingItem.config.unshift({
              open_time: timeSlot.open_time,
              close_time: timeSlot.close_time,
            });
        } else {
          grouped.push({
            day_of_week: timeSlot.day_of_week,
            is_active: timeSlot.is_active,
            config: [
              {
                open_time: timeSlot.open_time,
                close_time: timeSlot.close_time,
              },
            ],
          });
        }
        return grouped;
      },
      []
    );

    return formattedHours;
  }

  private _filterForAvailablePayment(branches: Branch[]) {
    return branches.filter((el) => {
      return this.checkForAvailablePaymentForBranch(el);
    });
  }

  private _loadBranches(businessId: string, lat?: number, lng?: number) {
    let params;
    if (lat && lng) params = { lat: lat, lng: lng };
    return this.http
      .get<{ data: APIBranch[] }>(
        environment.API_URL + 'businesses/' + businessId + '/branches',
        {
          params,
        }
      )
      .pipe(
        map((res) => {
          let branches = this._mapBranches(res.data);

          branches = this._filterForAvailablePayment(branches);

          this.branchesSubject.next(branches);

          this.checkForAvailableOrderTypes(branches);

          if (this.selectedBranchId) {
            const branch = branches.find(
              (el) => el.id == this.selectedBranchId
            ) as Branch;

            if (this.checkForAvailablePaymentForBranch(branch)) {
              branch.selected = true;
              this._orderService.orderBranch = branch;
            } else
              this._toastService.showToast({
                title: 'SHARED.SORRY',
                primaryMessage: 'BRANCHES.BRANCH_NOT_AVAILABLE',
              });
          }

          return branches;
        })
      );
  }

  private _getLocation(): Observable<GeolocationCoordinates> {
    return new Observable((observer) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          observer.next(position.coords);
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }
}
