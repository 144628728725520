export const locale = {
  lang: 'en',
  data: {
    CURRENCY: {
      INR: 'INR',
      KWD: 'KWD',
      SAR: 'SAR',
      GBP: 'GBP',
      EGP: 'EGP',
      AED: 'AED',
      OMR: 'OMR',
      BHD: 'BHD',
      USD: 'USD',
      QAR: 'QAR',
      EUR: 'EUR',
    },
    SHARED: {
      OFFLINE: 'No internet Connection',
      POWERED: 'Powered by Fooder®',
      ORDER: {
        TYPES: {
          DELIVERY: 'Delivery Order',
          DINE_IN: 'Dine in Order',
          PICK_UP: 'Pickup Order',
          DRIVE_THRU: 'Car Pickup Order',
        },
        STATUS: {
          PENDING: 'New',
          ACCEPTED: 'Accepted',
          REJECTED: 'Rejected',
          CANCELLED: 'Cancelled',
          BEING_PREPARED: 'In Kitchen',
          READY: 'Ready',
          COMPLETED: 'Completed',
          DELIVERED: 'Delivered',
          OUT_FOR_DELIVERY: 'Out For Delivery',
        },
      },
      DAYS: {
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
      },
      OK: 'Ok',
      CANCEL: 'Cancel',
      API_ERROR: 'Error occurred, please try again later',
      SORRY: 'Sorry',
      ERROR: 'Error',
      ALERT: 'Alert !',
    },
    HOME: {
      MENU: 'Menu',
      CALORIES: 'cal',
      VIEW_BASKET: 'View my basket',
      ORDER_TIME: 'Order time',
      ASAP: 'ASAP',
      CHANGE: 'Change',
      SCHEDULE: 'Schedule',
      SELECT_BRANCH: 'Select near branch',
      SELECT_ADDRESS: 'Select delivery address',
      SELECT_TABLE: 'Select your table',
      SELECT_ITEM: 'Select item',
      USE_CURRENT_ITEM: 'Do you want to use this current item',
      CONTINUE_WITH_CURRENT: 'Continue with current selection',
      MAKE_NEW_SELECTION: 'Make new selection',
      ALERT_CLOSED_UNTIL: 'The restaurant opens on ',
      ALERT_CLOSED: 'The restaurant is closed',
      ALERT_SCHEDULABLE: 'Order Ahead Available. the restaurant opens on ',
      TABLE: 'Table #',
      MINIMUM_CART_ALERT: 'Minimum cart value is',
      MOST_ORDERED: 'Most ordered',
    },
    PRODUCT: {
      CALORIES: 'cal',
      OPTIONAL: 'Optional',
      REQUIRED: 'Required',
      CHOOSE_UP_TO: 'Choose up to',
      CHOOSE_ONLY: 'Choose only',
      QUANTITY: 'Quantity',
      ADD_BUTTON: 'Add to basket',
      ITEMS_INCLUDED: 'items included',
      CHOOSE_AT_LEAST: 'Choose at least',
      ITEM: 'item from',
      ITEMS: 'items from',
      SHARE: 'Share',
      UPDATE: 'Update basket',
      PRODUCT_NOT_AVAILABLE_BRANCH:
        'This product not available in selected branch',
      PRODUCT_NOT_AVAILABLE_TIME: 'This product not available in this time',
    },
    ORDER_MODE: {
      TITLE: 'Order Mode',
      ORDER_TYPE: 'Order Type',
      ORDER_TIME: 'Order Time',
      ASAP: 'As soon as possible',
      SCHEDULE_TIME: 'Schedule time',
      SET_ORDER: 'Set mode',
      SET_TIME: 'set time',
      NO_AVAILABLE_TIME: 'No available time',
      TODAY: 'Today',
      AM: 'am',
      PM: 'pm',
      TIME_VALIDATION: 'Please select a valid time for scheduled Order',
    },
    ADDRESS: {
      TITLE: 'Delivering to',
      MY_ADDRESS: 'My addresses',
      QUICK_LOGIN: 'Quick Login to See your Addresses',
      LOGIN_BY_WA: 'Login By Whatsapp',
      ADD_ADDRESS: '+ Add new address',
      CHOOSE_ADDRESS: 'Choose my address',
      CREATE_ADDRESS: 'Create address',
      EDIT_LOCATION: 'Edit location in map',
      ADDRESS_DETAILS: 'Address Details',
      FULL_ADDRESS: 'Full Address *',
      ADDITIONAL_ADDRESS: 'Apt, villa or floor number *',
      DELIVERY_INSTRUCTION: 'Delivery instructions *',
      DELIVERY_INSTRUCTION_EG: 'E.g. Don’t ring bill door',
      ADDRESS_NAME: 'Address Name *',
      HOME: 'Home',
      OFFICE: 'Office',
      OTHER: 'Other',
      SAVE_ADDRESS: 'Save address',
      CANT_SEREVE: "Sorry, We can't serve you at the moment",
      CANT_SERVE_DESCRIPTION:
        'Branches are not available at selected address at the moment',
      NO_ADDRESS_AVAILABLE: 'No saved address available',
    },
    MAP: {
      TITLE: 'Add new address',
      SET_DELIVERY: 'Set delivery location',
      MARK_LOCATION:
        'Mark the exact location to help our driver deliver faster',
      SEARCH: 'Search for address',
      CHOOSE_ADDRESS: 'Choose my address',
      LOCATION_OFF: 'Device Location is off',
      TURN_LOCATION_ON:
        'Turning on device Location will ensure accurate address and hassle free delivery.',
      LOCATE_MANUAL: 'Locate manually',
      NOT_THERE_YET: "We're not there yet",
      NOT_THERE_YET_DESCRIPTION:
        'The delivery location is still outside of our delivery area. However, you can try a different location or select the pickup option to get your order at the outlet.',
      CHANGE_LOCATION: 'Change Location',
    },
    BRANCHES: {
      TITLE: 'Select branch',
      AVAILABLE: 'Available Branches',
      CLOSED: 'closed',
      PREORDER: 'Pre-order',
      SET_BRANCH: 'Set branch',
      CLOSED_UNTIL: 'Closed until',
      OPEN_UNTIL: 'Open until',
      NO_BRANCHES_AVAILABLE: 'No branches available',
      BRANCH_NOT_AVAILABLE: 'Branch not available now',
      KM: 'km',
      M: 'm',
    },
    LOGIN: {
      TITLE: 'Login',
      ENTER_YOUR: 'Enter your',
      WHATSAPP: 'WhatsApp',
      DESCRIPTION:
        'Log in with your WhatsApp number to take advantage of the offers and receive updates on your orders',
      WHATSAPP_NUMBER: 'WhatsApp number',
      ACCEPT: 'By clicking submit I accept',
      TERMS: 'terms & conditions',
      AND: 'and',
      PRIVACY: 'privacy policy',
      CHOOSE_COUNTRY: 'Choose your country',
      NUMBER_WRONG: 'Wrong number',
      NUMBER_REQUIRED: 'Whatsapp number required',
      SEARCH_HERE: 'Search here',
      OTP_SEND_ERROR: 'An error occurred while sending the verification code.',
      OTP_TIME_ERROR: 'OTP already sent, pleasae wait 1 minute and try again.',
      VERIFY_WHATSAPP: 'Verify your WhatsApp',
      OTP_SENT_DESCRIPTION:
        'We’ve sent you a WhatsApp with the verification code to ',
      NOT_RECEIVED: 'Haven’t receive OTP yet',
      RESEND: '( Re-send )',
      INVALID_CODE: 'The code is invalid.',
      WELCOME: 'Welcome 👋',
      SHARE_NAME: 'Please share with us your name',
      YOUR_NAME: 'Your Name',
      TYPE_FULLNAME: 'type full name here',
      NAME_VALIDATION_REQUIRED: 'Name required',
      NAME_VALIDATION_WRONG: 'Enter a valid name',
      SEC: 'sec',
      SAVE: 'Save',
      OK: 'Ok',
    },
    ERROR: {
      TITLE_RESTAURANT: 'Restaurant Not Found',
      TITLE_PAGE: 'Page Not Found',
      DESCRIPTION: 'The requested URL was not found on this server',
      BACK_TO_HOME: 'Back to Home',
      BANK_DECLINED: 'Your bank declined the payment',
      LINK_EXPIRED: 'Link expired',
      LINK_EXPIRED_DESCRIPTION: 'The requested URL is expired',
    },
    QR_CODE: {
      TITLE: 'Scan QR Code',
      DESCRIPTION: 'Please scan Qr Code on table',
      WRONG_ALERT: 'Wrong QR Code',
    },
    TABLE_NUMBER: {
      TITLE: 'Table Number',
      DESCRIPTION: 'Add your table number',
      ADD_TABLE: 'Add table',
      VALIDATION_MSG: 'Table number required',
    },
    CART: {
      TITLE: 'Complete your order',
      EDIT_ITEM: 'Edit item',
      MY_BASKET: 'My basket',
      SPECTIAL_INSTRUCTION: 'Special instructions',
      WITHOUT_BBQ: 'Without BBQ',
      EDIT: 'Edit',
      TABLE: 'Table #',
      CHECKOUT: 'Checkout',
      ITEMS_INCLUDED: 'items included',
      START_NEW_BASKET: 'Start a new basket?',
      CLEAR_BASKET: 'This will clear your basket with',
      START: 'Start',
      CANCEL: 'Cancel',
      CLEAR_CART: 'Clear cart',
      CLEAR_CART_DESC: 'Cart items will be cleared',
      CLEAR: 'Clear',
      SET_OPTIONS: 'Set options',
      CART_CHANGED: 'You cart changed according to selected branch',
    },
    CHECKOUT: {
      TITLE: 'Checkout',
      DELIVERY_TO: 'Delivery to',
      PICKUP_FROM: 'Pickup from',
      CAR_PICKUP_FROM: 'Car pickup from',
      DINING_AT: 'Dining at',
      CHANGE: 'Change',
      DELIVERY_TIME: 'Delivery time',
      PICKUP_TIME: 'Pickup time',
      DINING_TIME: 'Dining time',
      CAR_PICKUP_TIME: 'Car pickup time',
      ASAP: 'ASAP',
      APPLY_COUPON: 'Apply coupon',
      APPLY: 'Apply',
      REMOVE: 'Remove',
      VALID_TO: 'Valid to',
      PAYMENT_TYPE: 'Payment type',
      PAY_ONLINE: 'Pay online',
      PAY_WITH: 'Pay with',
      CASH_PICKUP: 'Cash on pickup',
      CASH_DELIVERY: 'Cash on delivery',
      CASH_CAR_PICKUP: 'Cash on car pickup',
      CASH_DINEIN: 'Cash on dine in',
      ONLINE: 'Online',
      ONLINE_PAYMENT: 'Online Payment',
      PAY_WITH_CREDIT: 'Pay with your Credit Card',
      POWERED_BY: 'Powered by',
      PAY_NOW: 'Pay Now',
      TRANSACTION_DECLINED: 'Transaction Declined',
      TRANSACTION_DECLINED_DESC:
        'sorry, your transaction has been declined, try to contact your bank or change credit Card',
      ORDER_PLACE_FAILED: 'An error occurred while sending the order',
      COUPON_PERCENTAGE_DESC:
        'Use {{code}} and get {{amount}}% up to {{max}}{{currency}} on order above {{min}} {{currency}}',
      COUPON_FIXED_DESC:
        'Use {{code}} and get {{amount}} {{currency}} on order above {{min}} {{currency}}',
      NOT_APPLICABLE_DESC: 'Coupon not applicable for this order.',
      WRONG_COUPON: 'Wrong coupon',
      INVALID_COUPON: 'Enter a valid coupon',
      WRONG_COUPON_DESC: 'No coupon found, try again',
      BILL_DETAILS: 'Bill Details',
      SUB_TOTAL: 'Sub Total',
      ITEM_VAT: 'Item Vat @ {{amount}}%',
      DISCOUNT: 'Discount %',
      DELIVERY_FEES: 'Delivery Fees',
      DELIVERY_VAT: 'Delivery Vat @ {{amount}}%',
      GRAND_TOTAL: 'Grand Total',
      VAT: 'VAT(incl.)',
      GST: 'GST(incl.)',
      TOTAL_ORDER_AMOUNT: 'Total Order amount',
      PLACE_ORDER: 'Place Order',
      BRANCH_MUST_SELECTED: 'You have to select a branch',
      TABLE_NUBER_MUST_SET: 'You have to Enter table number',
      ADDRESS_MUST_SELECTED: 'You have to select an address',
      OR_CANCEL: 'or cancel the order',
      MAXIMUM_CASH_ALERT: 'Maximum cash accepted is',
      ERRORS: {
        MAXIMUM_USE_PER_CUSTOMER:
          'You exceeds the maximum usage per customer for this coupon.',
      },
    },
    ACCOUNT: {
      PROFILE: 'My Profile',
      HEY: 'Hey,',
      LOGOUT: 'Logout',
      DETAILS: 'Account details',
      MY_ORDERS: 'My Orders',
      SUPPORT: 'Support',
      PRIVACY_POLICY: 'Privacy policy',
      MY_ACCOUNT: 'My Account',
      UPDATE_CONNECT: 'Update how we can contact you',
      NAME: 'Name',
      EMAIL: 'Email',
      PHONE_NUMBER: 'Phone number',
      WHATSAPP_NUMBER: 'Whatsapp number',
      SAVE: 'Save',
      ORDER: 'Order #',
      RE_ORDER: 'Re-order',
      ORDER_TYPE: 'Order type',
      ORDER_DETAILS: 'Order details',
      REVIEW_ORDER: 'Review order',
      NAME_REQUIRED: 'Name is required',
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_INVALID: 'Email not valid',
      PHONE_REQUIRED: 'Phone number is required ',
      PHONE_INVALID: 'Phone number not valid',
      DATA_CHANGED: 'Data has been changed successfully',
    },
    STATUS: {
      TITLE: 'Track your order',
      ORDER: 'Order #',
      RE_ORDER: 'Re-order',
      PAYMENT_TYPE: 'Payment type: ',
      ORDER_TYPE: 'Order type: ',
      VIEW_RECIEPT: 'View Receipt',
      DELIVERY_TO: 'Delivery to',
      PICKUP_FROM: 'Pickup from',
      CAR_PICKUP_FROM: 'Car pickup from',
      DINING_AT: 'Dining at',
      DELIVERY_TIME: 'Delivery time',
      PICKUP_TIME: 'Pickup time',
      DINING_TIME: 'Dining time',
      CAR_PICKUP_TIME: 'Car pickup time',
      ASAP: 'ASAP',
      CASH_PICKUP: 'Cash on pickup',
      CASH_DELIVERY: 'Cash on delivery',
      CASH_CAR_PICKUP: 'Cash on car pickup',
      CASH_DINEIN: 'Cash on dine in',
      ONLINE: 'Online',
      PENDING_DESC: 'Your Request has been sent on {{time}}',
      PENDING_DESC_2: 'Awaiting for branch confirmation',
      ACCEPTED_DESC: 'Your order has been confirmed',
      BEING_PREPARED_DESC: 'Your order is being prepared now',
      PICKUP_READY_DESC: 'Ready for Pick-up',
      DINEIN_READY_DESC: 'Ready for Dine-in',
      CANCELLED_DESC: 'Your order has been cancelled',
      REJECTED_DESC: 'Your order has been rejected',
      OUT_FOR_DELIVERY_DESC: 'Your order on its way to you',
      REVIEW_ORDER: 'Review order',
      DELIVER_TO_CAR: 'Deliver to your car',
      DELIVER_TO_CAR_DESC: 'Notify us when you reach our branch',
      ARRIVED_TO_BRANCH: 'Arrived to branch',
      UPDATE_CAR_DETAILS: 'Update car details',
      ENTER_VEHICLE: 'Enter your vehicle details',
      VEHICLE_NUMBER: 'Vehicle number',
      VEHICLE_NUMBER_REQUIRED: 'Vehicle number required',
      VEHICLE_COLOR: 'Vehicle color',
      VEHICLE_COLOR_REQUIRED: 'Vehicle color required',
      VEHICLE_MODEL: 'Vehicle model',
      VEHICLE_MODEL_REQUIRED: 'Vehicle model required',
      NEAR_LANDMARK: 'Near landmark',
      NEAR_LANDMARK_REQUIRED: 'Near landmark required',
      SUBMIT: 'Submit',
    },
    RATE: {
      TITLE: 'Rate your order',
      OVERALL_RATE: 'Overall Rate',
      FOOD_QUALITY: 'Food Quality',
      SERVICE_LEVEL: 'Service Level',
      CLEANNESS: 'Cleanness',
      STAFF: 'Staff',
      OVERALL_COMMENTS: 'Overall Comments',
      ADD_NOTE: 'Add note',
      ADD_REVIEW: 'Add Review',
      THANKS_FOR_RATING: 'Thanks for rating',
      THANKS_FOR_RATING_DESC: 'Please share your rating to google Map',
      BACK_HOME: 'Back to home',
      SHARE_REVIEW: 'Share your review',
    },
    DRIVER: {
      TITLE: 'Delivery Order',
      HEY: 'Hey, ',
      HAVE_DELIVERY_ORDER: 'You have an Delivery order',
      ORDER_NUM: 'Order #',
      PAYMENT_TYPE: 'Payment type',
      PICKUP_FROM: 'Pickup from',
      GET_DIRECTION: 'Get Direction',
      CLIENT_DETAILS: 'Client details',
      CALL_CLIENT: 'Call client',
      DELIVERY_FEES: 'Delivery fees',
      CLIENT_PAY: 'Client will pay',
      ACCEPT: 'Accept Order',
      DELIVERED: 'Delivered',
      NOT_AVAILABLE: 'No available orders',
      SORRY: 'Sorry',
      SORRY_DESCRIPTION:
        'Sorry, your order has assigned to another delivery, try to be quick.',
    },
  },
};
