import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, map, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { RestaurantService } from './restaurant.service';
import {
  LoginResponse,
  User,
  UserAddress,
  RidDetailsResponse,
  ServiceParams,
} from '../models';
import { LocalStorageService } from './local-storage.service';
import { LanguageService } from './language.service';
import { OrderService } from './order.service';
import { BranchService } from './branches.service';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { CartService } from './cart.service';
import { AnalyticsService } from './analytics.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject: BehaviorSubject<User | null>;
  public user$: Observable<User | null>;

  private contact_number: string;
  private otp: string;
  temporaryUserData: User | null; // use in changing user data after verifying otp

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _restaurantService: RestaurantService,
    private _localStorageService: LocalStorageService,
    private _languageService: LanguageService,
    private _orderService: OrderService,
    private _branchService: BranchService,
    private _toastService: ToastService,
    private _cartService: CartService,
    private _analyticsService: AnalyticsService
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user$ = this.userSubject.asObservable();
  }

  requestOtp(phoneNumber: string) {
    return this.http
      .post(environment.API_URL + 'auth/request-otp', {
        business_id: this._restaurantService?.restaurant?.id,
        phone_number: phoneNumber,
      })
      .pipe(
        map((res) => {
          this.contact_number = phoneNumber;
        })
      );
  }

  resendOtp() {
    return this.http.post(environment.API_URL + 'auth/request-otp', {
      business_id: this._restaurantService?.restaurant?.id,
      phone_number: this.contact_number,
    });
  }

  verifyOtp(otp: string) {
    this.otp = otp;
    return this.http
      .post<{ data: boolean }>(environment.API_URL + 'auth/verify-otp', {
        phone_number: this.contact,
        verification_code: otp,
      })
      .pipe(map((res) => res.data));
  }

  login(otp: string) {
    this.otp = otp;
    return this.http
      .post<LoginResponse>(environment.API_URL + 'auth/login', {
        phone_number: this.contact,
        verification_code: otp,
      })
      .pipe(
        map((res) => {
          this._localStorageService.setItem(
            'token',
            res.data.meta.access_token
          );
          this.userSubject.next(res.data);
        })
      );
  }

  signup(name: string) {
    return this.http
      .post<LoginResponse>(environment.API_URL + 'auth/signup', {
        name,
        phone_number: this.contact,
        verification_code: this.otp,
      })
      .pipe(
        map((res) => {
          this._localStorageService.setItem(
            'token',
            JSON.stringify(res.data.meta.access_token)
          );
          this.userSubject.next(res.data);
        })
      );
  }

  getRidDetails(rid: string, businessId: string) {
    return this.http
      .get<RidDetailsResponse>(environment.API_URL + 'customers/rid/' + rid)
      .pipe(
        map((res) => {
          this._orderService.orderChannel = 'wa';
          this._localStorageService.setItem(
            'token',
            res.service_params.accessToken
          );
          this._handleServiceParams(res.service_params, businessId);
          this.userSubject.next(res.customer);

          if (
            res.customer.wa_number.replace('+', '') ==
            res.customer.name.replace('+', '')
          )
            this.router.navigate(['login', 'name'], {
              queryParams: { 'new-customer': true },
            });

          this._analyticsService.waTrack(
            businessId,
            res.service_params.trackingId
          );

          return res;
        })
      );
  }

  getUser() {
    if (
      this._localStorageService.getItem('token') &&
      isPlatformBrowser(this.platformId)
    )
      this.http
        .get<{ data: User }>(environment.API_URL + 'customers/me')
        .subscribe({
          next: (res) => {
            this.userSubject.next(res.data);
            if (res.data.wa_number == res.data.name)
              this.router.navigate(['login', 'name'], {
                queryParams: { 'new-customer': true },
              });
            return res;
          },
          error: () => {
            this._localStorageService.removeItem('token');
          },
        });
  }

  updateUser(user: User) {
    return this.http
      .put<{ data: User }>(environment.API_URL + 'customers', user)
      .pipe(
        map((res) => {
          this.temporaryUserData = null;
          this.userSubject.next(res.data);
        })
      );
  }

  getAddresses(businessId: string) {
    return this.http
      .get<{ data: UserAddress[] }>(environment.API_URL + 'addresses', {
        params: { businessId },
      })
      .pipe(map((res) => res.data));
  }

  saveAddress(address: UserAddress) {
    return this.http
      .post<{ data: UserAddress }>(environment.API_URL + 'addresses', address)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  logout() {
    this._localStorageService.removeItem('token');
    this.userSubject.next(null);
  }

  public get contact() {
    return this.contact_number;
  }

  private _handleServiceParams(
    serviceParams: ServiceParams,
    businessId: string
  ) {
    this._languageService.setLanguage(serviceParams.language);

    if (serviceParams.tableNumber)
      this._orderService.orderTable = serviceParams.tableNumber;

    if (serviceParams.scheduledTo)
      this._orderService.orderTime = new Date(serviceParams.scheduledTo);

    if (serviceParams.trackingId)
      this._analyticsService.trackId = serviceParams.trackingId;

    if (serviceParams.branch) {
      this._branchService.selectedBranchId = serviceParams.branch;

      if (this._branchService.branches.length > 0) {
        const selectedBranch = this._branchService.branches.find(
          (el) => el.id == serviceParams.branch
        );
        if (
          selectedBranch &&
          this._branchService.checkForAvailablePaymentForBranch(selectedBranch)
        ) {
          selectedBranch.selected = true;
          this._orderService.orderBranch = selectedBranch;
          this._branchService.selectedBranchId = serviceParams.branch;
        } else {
          this._toastService.showToast({
            title: 'SHARED.SORRY',
            primaryMessage: 'BRANCHES.BRANCH_NOT_AVAILABLE',
          });
        }
      }
    }

    if (serviceParams.orderType)
      this._orderService.orderType = serviceParams.orderType;

    if (serviceParams.cart) {
      this._cartService.commerceCartProducts = serviceParams.cart;
    }

    // if (serviceParams.isPreviousOrder && serviceParams.lastOrderId) {
    //   this._orderService
    //     .getDetails(
    //       serviceParams.lastOrderId,
    //       'branch,items,items.options,address'
    //     )
    //     .subscribe((res) => {
    //       // check for dine in order only for previously order branch
    //       if (res.ordering_service == 'dine_in') {
    //         let isBranchAvailable = false;
    //         for (const branch of this._branchService.branches) {
    //           if (branch.id == res.branch.id && branch.isAvailableNow) {
    //             this._orderService.orderBranch = branch;
    //             isBranchAvailable = true;
    //           }
    //           if (!isBranchAvailable) {
    //             this._toastService.showToast({
    //               title: 'BRANCHES.BRANCH_NOT_AVAILABLE',
    //             });
    //             return;
    //           }
    //         }
    //       }

    //       this._cartService.reorder(res);
    //       this._orderService.orderTime = 'asap';
    //       this._orderService.orderType = res.ordering_service;

    //       this.router.navigate(['/checkout'], {
    //         queryParams: { 're-order': true },
    //       });
    //     });
    // }

    if (
      serviceParams.orderType == 'delivery' &&
      serviceParams.userLocation &&
      serviceParams.userLocation.id
    ) {
      this._orderService.orderAddress = serviceParams.userLocation;
      this._branchService
        .getDeliveryBranch(
          businessId,
          serviceParams.userLocation.lat.toString(),
          serviceParams.userLocation.lng.toString()
        )
        .subscribe(() => {
          this._orderService.orderAddress = {
            location: serviceParams.userLocation.location,
            lat: serviceParams.userLocation.lat,
            lng: serviceParams.userLocation.lng,
            instruction: serviceParams.userLocation.instruction,
            house_number: serviceParams.userLocation.house_number,
            name: serviceParams.userLocation.name,
            id: serviceParams.userLocation.id,
            selected: true,
          };
        });
    }

    if (
      serviceParams.orderType == 'delivery' &&
      serviceParams.userLocation &&
      !serviceParams.userLocation.id
    ) {
      this._branchService
        .getDeliveryBranch(
          businessId,
          serviceParams.userLocation.lat.toString(),
          serviceParams.userLocation.lng.toString()
        )
        .subscribe();
      this._orderService.orderAddress = {
        location: '',
        lat: serviceParams.userLocation.lat,
        lng: serviceParams.userLocation.lng,
        instruction: '',
        house_number: '',
        name: '',
        id: '',
        selected: true,
      };
      this.router.navigate(['order-mode/address-creation']);
    }

    if (serviceParams.orderType == 'delivery' && !serviceParams.userLocation)
      this.router.navigate(['order-mode/location']);
  }
}
