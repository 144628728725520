import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Restaurant } from '../models/restaurant.model';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  private restaurantSubject: BehaviorSubject<Restaurant | null>;
  public restaurant$: Observable<Restaurant | null>;

  private renderer: Renderer2;

  constructor(
    private _languageService: LanguageService,
    private http: HttpClient,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.restaurantSubject = new BehaviorSubject<Restaurant | null>(null);
    this.restaurant$ = this.restaurantSubject.asObservable();
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  getRestaurantDetails() {
    const domain: string =
      (this.document.defaultView as Window).location.hostname.includes(
        'localhost'
      ) ||
      (this.document.defaultView as Window).location.hostname.includes(
        '192.168'
      )
        ? 'marhaba.fooder.in'
        : (this.document.defaultView as Window).location.hostname;

    return this.http
      .get<{ data: Restaurant }>(environment.API_URL + 'businesses', {
        params: {
          domain,
          include: 'support,permission,best_seller,carousel,payment_suppliers',
        },
      })
      .pipe(
        map((res) => {
          this._setRestaurantTheme(res.data);
          this.restaurantSubject.next(res.data);
          return res.data;
        })
      );
  }

  private _setRestaurantTheme(restaurant: Restaurant) {
    // set icon
    if (restaurant.logo) {
      const favIcon: any = document.querySelector('#appIcon');
      favIcon.href = restaurant.logo;
    }

    // set background image
    if (restaurant.cover) {
      const backgroundDiv = document.body.querySelector('.background-div');
      this.renderer.setStyle(
        backgroundDiv,
        'background-image',
        `url(${restaurant.cover})`
      );
      this.renderer.addClass(backgroundDiv, 'covered');
    }

    // set title
    document.title =
      this._languageService.language == 'en'
        ? restaurant.name
        : restaurant.name_localized;

    // set colors
    const primaryColor = restaurant.base_color || '#fe724c';
    document.documentElement.style.setProperty('--primary-color', primaryColor);
  }

  get restaurant() {
    return this.restaurantSubject.value;
  }
}
