<div class="content-container">
  <div class="content-div">
    <div [@appAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>

      <ng-template #modalTemplate> </ng-template>
    </div>
    <div class="offline" #offlineRef>
      <app-offline></app-offline>
    </div>
  </div>
  <div class="background-div"></div>
</div>
