import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map, switchMap } from 'rxjs';

import {
  Cart,
  CommerceCartItem,
  CartModifier,
  CartProduct,
} from '../models/cart.model';
import {
  CategoriesResponse,
  MenuGroupProductBranch,
  Option,
  Product,
} from '../models/menu.model';
import { Restaurant } from '../models/restaurant.model';
import { DeliveryZone } from '../models/branch.model';
import { Coupon, Order, OrderType } from '../models';
import { RestaurantService } from './restaurant.service';
import {} from './branches.service';
import { OrderService } from './order.service';
import { MenuService } from './menu.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _cartItemsCount = 0;
  private cartSubject: BehaviorSubject<Cart>;
  public cart$: Observable<Cart>;
  private restaurant: Restaurant;
  private _cart: Cart = {
    items: [],
    items_total: 0,
    items_vat: 0,
    delivery: 0,
    delivery_vat: 0,
    total_price: 0,
    discount: 0,
    notes: null,
  };
  commerceCartProducts: CommerceCartItem[] = [];

  constructor(
    private _restaurantService: RestaurantService,
    private _orderService: OrderService,
    private _menuService: MenuService,
    private http: HttpClient
  ) {
    this.cartSubject = new BehaviorSubject<Cart>(this._cart);
    this.cart$ = this.cartSubject.asObservable();

    this._restaurantService.restaurant$.subscribe((res) => {
      if (res) this.restaurant = res;
    });
  }

  addItem(product: Product, selectedOptions: Option[]) {
    const modifiedProduct = this._modifyProductObject(product, selectedOptions);
    this.cart.items.push(modifiedProduct);
    this._updateCart();
  }

  modifyItemQuantity(productIndex: number, operation: 'plus' | 'minus') {
    if (operation == 'minus') {
      if (this.cart.items[productIndex].quantity > 1) {
        this.cart.items[productIndex].quantity--;
      } else {
        this.cart.items.splice(productIndex, 1);
      }
    } else {
      this.cart.items[productIndex].quantity++;
    }

    this._updateCart();
  }

  removeItem(productId: string) {
    const productIndexInCart = this.cart.items.findIndex(
      (el) => el.id == productId
    );
    this.cart.items.splice(productIndexInCart, 1);

    this._updateCart();
  }

  updateItem(product: Product, selectedOptions: Option[], itemIndex: number) {
    const modifiedProduct = this._modifyProductObject(product, selectedOptions);
    this.cart.items[itemIndex] = modifiedProduct;
    this._updateCart();
  }

  clearCart() {
    this._cart = {
      items: [],
      items_total: 0,
      items_vat: 0,
      delivery: 0,
      delivery_vat: 0,
      total_price: 0,
      notes: '',
      discount: 0,
    };
    this._cartItemsCount = 0;
    this.cartSubject.next(this._cart);
    this.removeCoupon();
  }

  addDeliveryFees(distance: number, deliveryZone: DeliveryZone) {
    let deliveryFees = 0;
    if (distance <= deliveryZone.delivery_distance) {
      deliveryFees = deliveryZone.delivery_fees;
    } else {
      deliveryFees =
        deliveryZone.delivery_fees +
        Math.ceil(distance - deliveryZone.delivery_distance) *
          deliveryZone.extra_distance_fees;
    }

    // calculate delivery fees and delivery vat according to restaurant tax type
    if (this.restaurant.include_tax) {
      this.cart.delivery_vat =
        deliveryFees * this.restaurant.delivery_tax * 0.01;
      this.cart.delivery = deliveryFees - this.cart.delivery_vat;
    } else {
      this.cart.delivery = deliveryFees;
      this.cart.delivery_vat =
        deliveryFees * this.restaurant.delivery_tax * 0.01;
    }

    this._updateCart();
  }

  isProductExistInCart(productId: string) {
    return this.cart.items.findIndex((el) => el.id == productId) >= 0;
  }

  duplicateProduct(productId: string) {
    this.cart.items.forEach((el) => {
      if (el.id == productId) {
        el.quantity++;
      }
    });
    this._updateCart();
  }

  applyCoupon(coupon: Coupon, updateCart: boolean = true) {
    if (coupon.type == 'FIXED_VALUE') {
      this._cart.discount = coupon.amount;
    } else {
      const discount =
        ((this._cart.items_total + this._cart.items_vat) * coupon.amount) / 100;
      if (discount > coupon.maximum_discount) {
        this._cart.discount = coupon.maximum_discount;
      } else {
        this._cart.discount = +discount.toFixed(2);
      }
    }

    if (updateCart) this._updateCart();
  }

  removeCoupon(updateCart: boolean = true) {
    this.cart.discount = 0;
    this._orderService.coupon = null;

    if (updateCart) this._updateCart();
  }

  checkCoupon() {
    const selectedCoupon = this._orderService.coupon;

    if (
      selectedCoupon &&
      selectedCoupon.minimum_order_value <
        this.cart.items_total + this.cart.items_vat
    ) {
      this.applyCoupon(selectedCoupon, false);
    } else {
      this.removeCoupon(false);
    }
  }

  reorder(order: Order) {
    this.clearCart();
    this._cart.items = order.items;
    this.notes = order.notes;
    this._updateCart();
  }

  // building cart items for chatbot commerce
  buildCart(
    products: Product[],
    cartParams: CommerceCartItem[],
    branchId: string
  ) {
    products.forEach((product) => {
      const cartItem = cartParams.find((el) => el.id == product.id);

      const modifiedProduct = this._handleProductAvailability(
        product,
        cartItem?.quantity || 1,
        branchId
      );
      this.addItem(
        {
          ...modifiedProduct,
          have_required_modifier: modifiedProduct.modifiers.length > 0,
        },
        []
      );
    });
  }

  CheckCartWithNewBranch(businessId: string, branch_id: string) {
    let dataChanged = false;
    const productsIds = this.cart.items.map((el) => el.id).join(',');

    return this._menuService
      .getProductsByIds(businessId, productsIds, branch_id)
      .pipe(
        map((products) => {
          products.forEach((menuProduct) => {
            const cartItemIndex = this.cart.items.findIndex(
              (el) => el.id == menuProduct.id
            );

            if (cartItemIndex >= 0) {
              this.cart.items[cartItemIndex].modifiers.forEach(
                (cartModifier) => {
                  cartModifier.options.forEach((cartOption) => {
                    menuProduct.modifiers.forEach((menuModifier) => {
                      menuModifier.modifier.options.forEach((menuOption) => {
                        if (menuOption.id == cartOption.id) {
                          if (
                            menuOption.branches[0] &&
                            menuOption.branches[0].pivot.status == 'active' &&
                            menuOption.branches[0].pivot.custom_price
                          ) {
                            dataChanged = true;
                            cartOption.unit_price =
                              menuOption.branches[0].pivot.custom_price;
                          } else {
                            cartOption.unit_price = menuOption.price;
                          }
                          cartOption.total_price =
                            cartOption.unit_price * cartOption.quantity;
                        }
                      });
                    });
                  });
                }
              );

              if (
                this.cart.items[cartItemIndex].unit_price !==
                this._getProductUnitPrice(menuProduct, branch_id)
              )
                dataChanged = true;

              this.cart.items[cartItemIndex].unit_price =
                this._getProductUnitPrice(menuProduct, branch_id);

              this.cart.items[cartItemIndex].total_price =
                this._calculateProductTotalPrice(menuProduct, []);
            }
          });

          this._updateCart();

          return dataChanged;
        })
      );
  }

  get cartItemsCount() {
    return this._cartItemsCount;
  }

  get cart() {
    return this.cartSubject.value;
  }

  set notes(notes: string) {
    this._cart.notes = notes;
    this.cartSubject.next(this._cart);
  }

  private _updateCart() {
    let totalItemsPrice = 0;
    this.cart.items.forEach((el) => {
      let itemPrice = el.unit_price;
      el.modifiers.forEach((modifierItem) => {
        modifierItem.options.forEach((option) => {
          itemPrice += option.total_price;
        });
      });
      totalItemsPrice += itemPrice * el.quantity;
    });

    if (this.restaurant.include_tax) {
      this.cart.items_vat = this.restaurant.item_tax
        ? +(totalItemsPrice * this.restaurant.item_tax * 0.01).toFixed(2)
        : 0;

      this.cart.items_total = totalItemsPrice - this.cart.items_vat;
    } else {
      this.cart.items_total = totalItemsPrice;

      this.cart.items_vat = this.restaurant.item_tax
        ? +(totalItemsPrice * this.restaurant.item_tax * 0.01).toFixed(2)
        : 0;
    }

    if (
      this._orderService.coupon &&
      this._orderService.coupon.minimum_order_value >
        this.cart.items_total + this.cart.items_vat
    )
      this.removeCoupon(false);

    this.checkCoupon();

    this.cart.total_price = +(
      this.cart.items_total +
      this.cart.items_vat +
      this.cart.delivery +
      this.cart.delivery_vat -
      this.cart.discount
    ).toFixed(2);

    this._cartItemsCount = this.cart.items.reduce(
      (prev, current) => (current.quantity ? prev + current.quantity : prev),
      0
    );

    this.cartSubject.next(this.cart);
  }

  private _modifyProductObject(
    product: Product,
    selectedOptions: Option[]
  ): CartProduct {
    const modifiedProduct: CartProduct = {
      id: product.id,
      name: product.name,
      name_localized: product.name_localized,
      quantity: product.quantity,
      unit_price: product.price,
      total_price: this._calculateProductTotalPrice(product, selectedOptions),
      modifiers: this._modifySelectedOptions(selectedOptions),
      have_required_modifier: product.have_required_modifier,
    };

    return modifiedProduct;
  }

  private _modifySelectedOptions(selectedOptions: Option[]): CartModifier[] {
    const cartModifiers: CartModifier[] = [];

    selectedOptions.forEach((option) => {
      const cartModifierIndex = cartModifiers.findIndex(
        (el) => el.id == option.modifier_id
      );
      if (cartModifierIndex == -1) {
        const cartModifierItem: CartModifier = {
          name: option.modifierName,
          name_localized: option.modifierNameLocalized,
          id: option.modifier_id,
          options: [
            {
              id: option.id,
              name: option.name,
              name_localized: option.name_localized,
              unit_price: option.price,
              quantity: option.quantity,
              total_price: option.price * option.quantity,
            },
          ],
        };
        cartModifiers.push(cartModifierItem);
      } else {
        cartModifiers[cartModifierIndex].options.push({
          id: option.id,
          name: option.name,
          name_localized: option.name_localized,
          unit_price: option.price,
          quantity: option.quantity,
          total_price: option.price * option.quantity,
        });
      }
    });

    return cartModifiers;
  }

  private _calculateProductTotalPrice(
    product: Product,
    selectedOptions: Option[]
  ): number {
    let productPrice = product.price;

    selectedOptions.forEach((option) => {
      productPrice += option.price * option.quantity;
    });

    return productPrice * product.quantity;
  }

  private _handleProductAvailability(
    product: Product,
    productQuantity: number,
    selectedBranchId: string
  ): Product {
    const modifiedProduct: Product = JSON.parse(JSON.stringify(product));
    modifiedProduct.modifiers = [];
    modifiedProduct.quantity = productQuantity;

    //check for custom branches for product
    modifiedProduct.branches.forEach((productBranch: any) => {
      // check for inactive
      if (
        productBranch.branch.id == selectedBranchId &&
        (productBranch.pivot.status == 'inactive' ||
          (productBranch.pivot.paused_until &&
            new Date() < new Date(productBranch.pivot.paused_until)))
      ) {
        return;
      }

      // check for custom price
      if (
        productBranch.branch.id == selectedBranchId &&
        productBranch.pivot.status == 'active' &&
        productBranch.pivot.custom_price
      ) {
        modifiedProduct.price = productBranch.pivot.custom_price;
      }
    });

    //check for custom branches for options
    product.modifiers.forEach((modifierObj, modifierIndex) => {
      modifierObj.modifier.options.forEach((option, optionIndex) => {
        if (option.branches.length == 0) {
          const existIndex = modifiedProduct.modifiers.findIndex(
            (el) => el.modifier.id == modifierObj.modifier.id
          );

          if (existIndex >= 0)
            modifiedProduct.modifiers[modifierIndex].modifier.options.push(
              option
            );
          else
            modifiedProduct.modifiers.push({
              pivot: modifierObj.pivot,
              modifier: {
                ...modifierObj.modifier,
                options: [option],
              },
            });
        }

        option.branches.forEach((optionBranch) => {
          // check for inactive
          if (
            optionBranch.branch_id == selectedBranchId &&
            (optionBranch.pivot.status == 'inactive' ||
              (optionBranch.pivot.paused_until &&
                new Date() < new Date(optionBranch.pivot.paused_until)))
          ) {
            modifiedProduct.modifiers[modifierIndex].modifier.options[
              optionIndex
            ].status = 'inactive';
            return;
          }

          // check for custom price
          if (
            optionBranch.branch_id == selectedBranchId &&
            optionBranch.pivot.status == 'active' &&
            optionBranch.pivot.custom_price
          ) {
            const existIndex = modifiedProduct.modifiers.findIndex(
              (el) => el.modifier.id == modifierObj.modifier.id
            );

            if (existIndex >= 0)
              modifiedProduct.modifiers[modifierIndex].modifier.options.push({
                ...option,
                price: optionBranch.pivot.custom_price,
                quantity: 1,
              });
            else
              modifiedProduct.modifiers.push({
                pivot: modifierObj.pivot,
                modifier: {
                  ...modifierObj.modifier,
                  options: [
                    {
                      ...option,
                      price: optionBranch.pivot.custom_price,
                      quantity: 1,
                    },
                  ],
                },
              });
          }
        });
      });
    });

    return modifiedProduct;
  }

  private _getProductUnitPrice(product: Product, branchId: string) {
    let price = product.price;

    product.branches.forEach((el: any) => {
      if (
        el &&
        el.branch.id == branchId &&
        el.pivot.status == 'active' &&
        el.pivot.custom_price
      )
        price = el.pivot.custom_price;
    });

    return price;
  }
}
