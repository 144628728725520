import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { combineLatest, concatMap, of, switchMap } from 'rxjs';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public trackId: string;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private _locationService: LocationService
  ) {}

  visit(businessId: string) {
    this._locationService.getLocation().subscribe((location) => {
      let params;
      const visit_id = this.localStorageService.getItem('visit_id');
      if (visit_id) params = { visit_id };

      this.http
        .post<{ data: { visit_id: string } }>(
          environment.API_URL + 'businesses/' + businessId + '/visits',
          {
            ...location,
          },
          {
            params,
          }
        )
        .subscribe((res) => {
          this.localStorageService.setItem('visit_id', res.data.visit_id);
        });
    });
  }

  waTrack(businessId: string, trackId: string) {
    return this.http.put<{ data: any }>(
      environment.API_URL +
        'businesses/' +
        businessId +
        '/wa-chats/tracks/' +
        trackId,
      {}
    );
  }
}
