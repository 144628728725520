<div class="qrCode">
  <div class="qrCode-container">
    <button class="qrCode-close" routerLink="">
      <svg><use href="./assets/images/icons.svg#close"></use></svg>
    </button>

    <div class="qrCode-restaurantLogo">
      <img src="./assets/images/fooder-icon.svg" />
    </div>

    <div class="qrCode-description">
      <p>{{ "QR_CODE.TITLE" | translate }}</p>
      <p>{{ "QR_CODE.DESCRIPTION" | translate }}</p>
    </div>

    <div class="qrCode-video">
      <svg><use href="./assets/images/icons.svg#qrCode"></use></svg>
      <video id="qrVideo"></video>
    </div>
  </div>

  <div class="qrCode-validation" [ngClass]="{ show: notValid }">
    <p>{{ "QR_CODE.WRONG_ALERT" | translate }}</p>
  </div>
</div>
