import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable, map } from 'rxjs';

import { LanguageService } from 'src/app/core/services/language.service';

@Pipe({ name: 'arNumberFormat' })
export class ArabicNumberFormatPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(n: string | number, compact: boolean = false): Observable<string> {
    if (n === null || n === undefined) {
      return from('');
    }
    let number = Number(n);
    let signDisplay: 'never' | 'exceptZero' = 'never';

    return this.languageService.language$.pipe(
      map((res) => (res === 'en' ? 'en-US' : 'ar-SA')),
      map((locale) =>
        new Intl.NumberFormat(locale, {
          useGrouping: false,
          signDisplay,
          notation: compact ? 'compact' : 'standard',
        }).format(number)
      )
    );
  }
}
