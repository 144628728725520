<div class="toastContainer">
  <div class="customToast">
    <header>
      <button (click)="close($event, undefined)">
        <svg><use href="./assets/images/icons.svg#circleClose"></use></svg>
      </button>
    </header>

    <body>
      <div class="title">{{ title }}</div>
      <div class="message">{{ message }}</div>
      <div *ngIf="secondaryMessage" class="message">
        {{ secondaryMessage }}
      </div>
    </body>

    <footer>
      <button (click)="close($event, 'primary')">
        {{ primaryButtonText }}
      </button>
      <button *ngIf="secondaryButtonText" (click)="close($event, 'secondary')">
        {{ secondaryButtonText }}
      </button>
    </footer>
  </div>
</div>
