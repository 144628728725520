import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const AccessGuard = async () => {
  const router = inject(Router);

  if (router.getCurrentNavigation()?.previousNavigation) return true;
  else {
    router.navigate(['']);
    return false;
  }
};
