import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  setDirection(direction: 'rtl' | 'ltr'): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dir = direction;
    }
  }

  isBrowserPlatform(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
